import { useCallback, useState } from "react";
import { Title } from '../../utils/title';
import { fetchApi } from "../../services/api";
import { Loader } from "@progress/kendo-react-indicators";
import { CompositeFilterDescriptor, DataSourceRequestState, toDataSourceRequestString } from "@progress/kendo-data-query";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import DateCell from "../../components/cells/DateCell";
import { DateFilter, EnumFilter, Filter, NumericFilter, Operators, TextFilter } from "@progress/kendo-react-data-tools";
import { Button } from "@progress/kendo-react-buttons";
import { PositionsType, positionEventName, resetDataState } from "views/AssetVehicles/Positions";
import { Link } from "react-router-dom";
import { useUpdateEffect } from "usehooks-ts";

export const DataSourceFilter = (props: any) => {
    return (
        <EnumFilter
            {...props}
            data={[
                { text: 'Manual', value: 0 },
                { text: 'Omnitracs', value: 1 },
                { text: 'Driver App', value: 2 },
                { text: 'Carrier App', value: 3 },
                { text: 'Full Circle', value: 4 },
                { text: 'Coyote', value: 5 },
                { text: 'Wheel King', value: 6 },
                { text: 'Carrier Web', value: 7 },
                { text: 'Spireon', value: 8 },
                { text: 'Sylectus', value: 9 },
                { text: 'Tracking URL', value: 10 },
                { text: 'Samsara', value: 11 },
            ]}
        />
    );
};

const Positions = () => {

    const [loading, setLoading] = useState(false);
    const [positions, setPositions] = useState<PositionsType>();
    const [dataState, setDataState] = useState<DataSourceRequestState>(resetDataState);
    const [filter, setFilter] = useState<CompositeFilterDescriptor>({
        logic: 'and', filters: [
            { field: "TripNumber", operator: "eq", value: null },
        ]
    });

    const refresh = useCallback(() => {
      setLoading(true);
      const queryStr = `${toDataSourceRequestString(dataState)}`;
      fetchApi(`/api/Trip/Positions?${queryStr}`, {}, 'POST')
          .then(data => {
              setLoading(false);
              setPositions(data);
          })
          .catch(err => {
              alert(err);
              setLoading(false);
          });
  }, [dataState]);

  useUpdateEffect(() => {
      refresh();
  }, [refresh]);

    return (<div className="container-fluid mt-2">
        <Title string="Positions" />
        {loading && <div className="k-pos-absolute k-top-center mt-5">
            <Loader type="converging-spinner" />
        </div>}
        <div>
            <Filter
                className="kendoFilterControl"
                value={filter}
                onChange={(e) => {
                    setFilter(e.filter);
                }}
                fields={[
                    {
                        name: "VehicleNumber",
                        label: "Vehicle #",
                        filter: TextFilter,
                        operators: Operators.text,
                    },
                    {
                        name: "TripNumber",
                        label: "Trip #",
                        filter: NumericFilter,
                        operators: Operators.numeric,
                    },
                    {
                        name: "TrailerNumber",
                        label: "Trailer #",
                        filter: TextFilter,
                        operators: Operators.text,
                    },
                    {
                        name: "DateTime",
                        label: "Date",
                        filter: DateFilter,
                        operators: Operators.date,
                    },
                    {
                        name: "City",
                        label: "City",
                        filter: TextFilter,
                        operators: Operators.text,
                    },
                    {
                        name: "State",
                        label: "State",
                        filter: TextFilter,
                        operators: Operators.text,
                    },
                    {
                        name: "Speed",
                        label: "Speed",
                        filter: NumericFilter,
                        operators: Operators.numeric,
                    },
                    {
                        name: "RepeatCount",
                        label: "Repeated",
                        filter: NumericFilter,
                        operators: Operators.numeric
                    },
                    {
                        name: "Distance",
                        label: "Distance",
                        filter: NumericFilter,
                        operators: Operators.numeric
                    },
                    {
                        name: "DataSource",
                        label: "Source",
                        filter: DataSourceFilter,
                        operators: [{ text: 'filter.eqOperator', operator: 'eq' }]
                    },
                    {
                        name: "UserNumber",
                        label: "User",
                        filter: TextFilter,
                        operators: Operators.text,
                    },
                ]}
            />
            <label className="ml-2" style={{ verticalAlign: "bottom", marginBottom: "10px" }}>
                <Button
                    themeColor="primary"
                    onClick={() => setDataState({ ...dataState, filter: filter })}
                >Search
                </Button>
            </label>
        </div>
        {positions && <Grid
            {...dataState}
            data={positions.Data}
            total={positions.Total}
            sortable
            pageable
            onDataStateChange={(e) => setDataState(e.dataState)}
        >
            <Column field="VehicleNumber" title="Vehicle #" cell={(e) => <td>
                <Link style={{ color: "#007bff" }} to={`/Assets/Vehicle/${e.dataItem.VehicleID}`}>{e.dataItem.VehicleNumber}</Link>
            </td>} />
            <Column field="TripNumber" title="Trip #" cell={(e) => <td>
                <Link style={{ color: "#007bff" }} to={`/Trip/${e.dataItem.TripID}`}>{e.dataItem.TripNumber}</Link>
            </td>} />
            <Column field="TrailerNumber" title="Trailer #" cell={(e) => <td>
                <Link style={{ color: "#007bff" }} to={`/Assets/Trailer/${e.dataItem.TrailerID}`}>{e.dataItem.TrailerNumber}</Link>
            </td>} />
            <Column field="DateTime" title="Date" filterable={false} filter="date" cell={DateCell} />
            <Column field="City" />
            <Column field="State" />
            <Column field="Speed" filter="numeric" format="{0:n0} mph" />
            <Column field="Direction" filter="numeric" />
            <Column field="RepeatCount" title="Repeated" filter="numeric" />
            <Column field="Distance" filter="numeric" format="{0:n0} mi" />
            <Column field="DataSourceName" title="Source" />
            <Column field="UserNumber" title="User" />
            <Column field="Event" filterable={false} cell={(e) => <td>{positionEventName(e.dataItem.Event)}</td>} />
        </Grid>}
    </div>);
}

export default Positions;