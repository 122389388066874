import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Title } from '../../utils/title';
import Moment from 'moment';
import { debounce } from 'ts-debounce';
import * as signalR from '@microsoft/signalr';
import { Button, ButtonGroup, DropDownButton, SplitButton } from '@progress/kendo-react-buttons';
import { NumericTextBox, NumericTextBoxHandle } from '@progress/kendo-react-inputs';
import { TimePicker } from '@progress/kendo-react-dateinputs';
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import EnterStop from './EnterStop';
import EnterFreight from './EnterFreight';
import StopTransitData, { TransitValue } from './StopTransitData';
import QuotePanel, { QuoteValue, QuoteLineItemValue, ChargeType, SmartbidValue, WinPercentageChartValue } from './Quote';
import VehiclesPanel, { VehicleValue, AllianceVehicleValue, OfferValue, CarrierValue, FedExValue, DHLValue, EShippingValue, UPSValue } from './Vehicles';
import MapPanel from './Map';
import RecentPanel from './Recent';
import { fetchApi } from '../../services/api';
import { LoadOneReconnectPolicy } from '../../services/signalr/retryPolicy';
import QuoteEmailTemplate from './QuoteEmailTemplate';
import QuoteEmailTemplateCarrier from './QuoteEmailTemplateCarrier';
import './snackbar.css';

import Checkbox from '../../components/Checkbox';
import { openWindow } from '../../services/openWindow';
import AuditLogs from './AuditLogs';
import CarrierReviews from '../CarrierReviews';
import CustomerInput from '../../components/CustomerInput';
import CustomerContactInput from '../../components/CustomerContactInput';
import BrokerInput from '../../components/BrokerInput';
import RGL, { WidthProvider } from "react-grid-layout";
import { CreateOrder } from './CreateOrder';
import { FrontReply } from './FrontReply';
import { PersonalizedUrl } from './PersonalizedUrl';
import PostLoadOption, { LoadBoard, LoadPosting } from './PostLoadOption';
import { Dialog, DialogActionsBar, Window, WindowActionsBar } from '@progress/kendo-react-dialogs';
import { ILink } from '../../types/link';
import { Coordinate } from '../../types/address';
import { IDName } from '../../types/idname';
import { isSorted } from '../../utils/utils';
import { ViewModel } from 'TypeGen/Quote/Get/view-model';
import { QuoteStopType } from 'TypeGen/Quote/quote-stop-type';
import { QuoteStopVerb } from 'TypeGen/Quote/quote-stop-verb';
import { JsonResponse } from 'TypeGen/json-response';
import { ComposeEmail } from 'views/Track/ComposeEmail';
import { Link } from 'react-router-dom';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import DateCell from 'components/cells/DateCell';
import TheadIDCell from './Recent/ThreadIDCell';
import { chevronDownIcon, chevronUpIcon } from '@progress/kendo-svg-icons';

const ReactGridLayout = WidthProvider(RGL);

export type EnterStopViewModel = {
  QuoteStopID?: number;
  Sequence: number;
  StopType: QuoteStopType;
  StopVerb: QuoteStopVerb;
  CustomerID: number;
  CustomerNumber: string;
  Location: string | any;
  DateTime: string;
  LatestDateTime?: string;
}

export type EnterFreightViewModel = {
  QuoteFreightID?: number;
  PickupStopSequence: number;
  DeliveryStopSequence: number;
  Weight: number;
  WeightUnitOfMeasure: 0 | 1;
  Pieces: number;
  Length: number;
  Width: number;
  Height: number;
  DimsUnitOfMeasure: 0 | 1 | 2;
  Stackable: boolean;
  StackableLimit: number;
  Rotatable: boolean;
}

export type VehicleTransitTimeValue = {
  VehicleId: number;
  HasHoursRemaining: boolean;
  InServiceBackOn: boolean;
  TransitTimeTooltip: string;
  Hash: string;
}

type State = {
  QuoteID: number;
  OrderID: number | null;
  OrderNumber: number | null;
  OrderStatus: number | null;
  OrderModNumDiff: number | null;
  Unassigned: boolean;
  ThreadID: string;
  RouteName: string;
  CreatedByUserName: string;
  IsTemplate: boolean;
  PreviousQuoteID: number;
  Stops: EnterStopViewModel[];
  Freight: EnterFreightViewModel[];
  TSA: boolean;
  FAST: boolean;
  HazMat: boolean;
  DockHigh: boolean;
  LiftGate: boolean;
  AirRide: boolean;
  OverrideDistance: number;
  VehicleTypeID: number;
  CommodityTypeID: number;
  BillingCustomerID: number;
  BillingCustomerNumber: string;
  BillingInstructions: Array<{ Display: number, Instruction: string }>;
  PCMilerVersion: number | null;
  AuthorizationCustomerID: number;
  AuthorizationCustomerNumber: string;
  AuthorizationInstructions: Array<{ Display: number, Instruction: string }>;
  AuthorizationCustomerContactID: number;
  AuthorizationCustomerContacts: IDName[];
  BrokerID: number;
  BrokerName: string;
  BookingSource: number;
  Note: string;

  DeadheadMiles: number;
  RemainingHoursofService: Date;

  AvgWaitTimeData: AvgWaitTimeValue[];
  AvgWaitTimeDataIsLoading: boolean;

  TransitData: TransitValue | null;
  TransitDataIsLoading: boolean;
  QuoteData: QuoteValue | null;
  QuoteDataIsLoading: boolean;
  QuoteDataOutdated: boolean;
  SmartbidData: SmartbidValue | null;
  SmartbidDataIsLoading: boolean;
  SmartbidDataUsed: boolean;
  SmartbidRateUpDown: number | null;
  SmartbidWinPercentagePrevious: number | null;
  SmartbidWinPercentageCurrent: number | null;
  WinPercentageData: WinPercentageChartValue | null;
  VehicleData: VehicleValue[] | null;
  VehicleDataIsLoading: boolean;
  DefaultVehicleFilters: string[];
  Distance: number;
  Margin: number;
  AllianceVehicleData: AllianceVehicleValue[] | null;
  CarrierData: CarrierValue[] | null;
  OfferData: OfferValue[] | null;
  OfferDataIsLoading: boolean;
  FedExRateData: FedExValue | null;
  DHLRateData: DHLValue | null;
  EShippingRateData: EShippingValue | null;
  UPSRateData: UPSValue | null;
  FuelRate: number;
  BidAmount?: number;
  SelectedCarrierOfferID?: number;

  QuoteEmailTemplate: boolean;
  QuoteEmailVehicle: VehicleValue | null;
  QuoteEmailTemplateCarrier: OfferValue | null;
  ShowAuditLogs: boolean;
  ShowStatusUpdate: boolean;
  ShowFrontReply: boolean;
  ShowPersonalizedUrl: boolean;
  SnackBarText: string;
  CarrierReviewPhone: string;
  CarrierReviewName: string;

  ValidationRequestHash: string;
  ValidationRequestHashTransit: string;
  ValidationRequestHashRate: string;
  ValidationRequestHashFuelRate: string;
  ValidationRequestHashVehicles: string;
  ValidationRequestHashExternalRates: string;
  ValidationRequestHashAvgWaitTimes: string;
  PendingChangeTypes: string[];
  IsSaving: boolean;
  DuplicateQuotes: DuplicateQuote[];

  Links: ILink[];
  Locations: EnterStopViewModel[];

  ShowOrderPostingDropdown: boolean;
  ShowOrderPostingAllInRateDialog: boolean;
  CurrentLoadPosting: LoadPosting;

  EditLayout: boolean;
  Layout: ReactGridLayout.Layout[];
  PrevLayout: ReactGridLayout.Layout[];
  Collapse: boolean;
}

export type DuplicateQuote = {
  QuoteID: number;
  ThreadID: string;
  VehicleTypeName: string;
  TotalCharges: number;
  CreatedDateTime: string;
  CreatedByUserName: string;
  SourceName: string;
}

export type UpdateQuoteOfferCount = {
  QuoteID: number;
  DeclinedOfferCount: number;
  ExpiredOfferCount: number;
  AcceptedOfferCount: number;
}

export type UpdateQuoteAssigned = {
  QuoteID: number;
  CreatedByUserName: string;
}

type AvgWaitTimeValue = {
  AvgWaitTime: string;
  Formatted: string;
}

type Props = RouteComponentProps<{
  quoteId: string;
}>;

export const vehicleTypes = [
  { value: 0, text: 'Select Vehicle Size' },
  { value: 1, text: 'CARGO VAN (L96xW48xH48) 2000 lbs. 50 mph' },
  { value: 2, text: 'SMALL STRAIGHT (L216xW84xH84) 10000 lbs. 45 mph' },
  { value: 3, text: 'LARGE STRAIGHT (L288xW96xH96) 14000 lbs. 45 mph' },
  { value: 4, text: 'TRACTOR (L636xW102xH110) 44000 lbs. 45 mph' },
  { value: 5, text: 'FLAT BED (L636xW102xH102) 47000 lbs. 45 mph' },
  { value: 6, text: 'AIR FREIGHT (L636xW102xH110) 50000 lbs. 500 mph' },
  { value: 7, text: 'REEFER (L636xW102xH110) 50000 lbs. 45 mph' },
  { value: 8, text: 'SPECIALIZED (L636xW102xH110) 50000 lbs. 45 mph' },
  { value: 9, text: 'FLATBED CURTAIN (L636xW102xH102) 44000 lbs. 45 mph' },
  { value: 10, text: 'FLATBED W/TARPS (L576xW102xH102) 47000 lbs. 45 mph' },
  { value: 11, text: 'STEP CURTAIN (L636xW102xH118) 43000 lbs. 45 mph' },
  { value: 12, text: 'OPEN STEP (L636xW102xH122) 47000 lbs. 45 mph' },
  { value: 13, text: 'OVERSIZE FLAT (L636xW102xH102) 47000 lbs. 45 mph' },
  { value: 14, text: 'OVERSIZE STEP (L636xW102xH122) 47000 lbs. 45 mph' },
  { value: 15, text: 'SPRINTER (L96xW48xH70) 3000 lbs. 50 mph' },
  { value: 16, text: '53 (L636xW102xH110) 44000 lbs. 45 mph' },
  { value: 17, text: 'DOUBLE DROP RGN (L636xW102xH138) 38000 lbs. 45 mph' },
  { value: 18, text: 'FLAT OR STEP RETRACTABLE (L636xW102xH110) 44000 lbs. 45 mph' },
  { value: 19, text: 'DOUBLE DROP CURTAINSIDE (L636xW102xH138) 38000 lbs. 45 mph' },
  { value: 20, text: 'AIR CHARTER (L0xW0xH0) 0 lbs. 45 mph' },
  { value: 21, text: 'CONTAINER (L102xW40xH40) 40000 lbs. 45 mph' },
  { value: 22, text: 'LTL (L0xW0xH0) 0 lbs. 45 mph' },
  { value: 23, text: 'HOTSHOT (L40xW96xH0) 16500 lbs. 45 mph' },
  { value: 24, text: 'HAND CARRY (L636xW102xH110) 50000 lbs. 500 mph' },
];

export const accessorialsNoDriverPay = [
  450, 960, 1000, 1300, 2109, 2540, 2560, 2561, 2570, 2580, 3090, 50603
];

export const accessorialsFullDriverPay = [
  900, 1060
];

class Quote extends React.Component<Props, State> {

  private connection: signalR.HubConnection | null;
  private reconnectSignalr: boolean;
  private quotePanel: React.RefObject<QuotePanel>;
  private vehiclesPanel: React.RefObject<VehiclesPanel>;
  private recentPanel: React.RefObject<RecentPanel>;
  private postLoadOption: React.RefObject<PostLoadOption>;
  private orderPostingAllInRateInput: React.RefObject<NumericTextBoxHandle>;

  private bookingSources = [
    { value: 0, text: 'Booking Source' },
    { value: 1, text: 'Web' },
    { value: 2, text: 'Email' },
    { value: 3, text: 'Phone' },
    { value: 4, text: 'Sylectus' }
  ];

  private defaultLayout = [
    { i: 'quote', x: 0, y: 0, w: 6, h: 6, static: true },
    { i: 'map', x: 6, y: 0, w: 6, h: 6, static: true },
    { i: 'vehicles', x: 0, y: 6, w: 12, h: 7, static: true },
    { i: 'recent', x: 0, y: 13, w: 12, h: 6, static: true }
  ] as ReactGridLayout.Layout[];

  constructor(props: Props) {
    super(props);

    this.connection = null;
    this.reconnectSignalr = true;

    this.quotePanel = React.createRef();
    this.vehiclesPanel = React.createRef();
    this.recentPanel = React.createRef();
    this.postLoadOption = React.createRef();
    this.orderPostingAllInRateInput = React.createRef();

    this.state = this.emptyState();

    this.createSignalRConnection = this.createSignalRConnection.bind(this);
    this.updateQuote = this.updateQuote.bind(this);
    this.addPair = this.addPair.bind(this);
    this.addStop = this.addStop.bind(this);
    this.removeStop = this.removeStop.bind(this);
    this.optimizeStops = this.optimizeStops.bind(this);
    this.addFreight = this.addFreight.bind(this);
    this.copyFreight = this.copyFreight.bind(this);
    this.removeFreight = this.removeFreight.bind(this);
    this.toggleTSA = this.toggleTSA.bind(this);
    this.toggleFAST = this.toggleFAST.bind(this);
    this.toggleHazMat = this.toggleHazMat.bind(this);
    this.toggleDockHigh = this.toggleDockHigh.bind(this);
    this.toggleLiftGate = this.toggleLiftGate.bind(this);
    this.toggleAirRide = this.toggleAirRide.bind(this);
    this.handleVehicleTypeChange = this.handleVehicleTypeChange.bind(this);
    this.handleBookingSourceChange = this.handleBookingSourceChange.bind(this);
    this.updateBillingSpecialInstructions = this.updateBillingSpecialInstructions.bind(this);
    this.updateAuthorizationSpecialInstructions = this.updateAuthorizationSpecialInstructions.bind(this);
    this.openThread = this.openThread.bind(this);
    this.addCharge = this.addCharge.bind(this);
    this.updateCharge = this.updateCharge.bind(this);
    this.removeCharge = this.removeCharge.bind(this);
    this.replaceCharges = this.replaceCharges.bind(this);
    this.addQuote = this.addQuote.bind(this);
    this.reAddQuote = this.reAddQuote.bind(this);
    this.selfAssign = this.selfAssign.bind(this);
    this.openOrder = this.openOrder.bind(this);
    this.dispatchOrder = this.dispatchOrder.bind(this);
    this.updateQuoteFromOrder = this.updateQuoteFromOrder.bind(this);
    this.openPersonalizedUrl = this.openPersonalizedUrl.bind(this);
    this.reserveQuote = this.reserveQuote.bind(this);
    this.createPersonalizedUrl = this.createPersonalizedUrl.bind(this);
    this.markTemplate = this.markTemplate.bind(this);
    this.dispatchQuote = this.dispatchQuote.bind(this);
    this.cancelQuote = this.cancelQuote.bind(this);
    this.offerQuote = this.offerQuote.bind(this);
    this.reOfferQuote = this.reOfferQuote.bind(this);
    this.reserve = this.reserve.bind(this);
    this.transfer = this.transfer.bind(this);
    this.release = this.release.bind(this);
    this.turndown = this.turndown.bind(this);
    this.updateDistance = this.updateDistance.bind(this);
    this.callSylectus = this.callSylectus.bind(this);
    this.callLoadOneTrucks = this.callLoadOneTrucks.bind(this);
    this.populateAuthorizationCustomerContacts = this.populateAuthorizationCustomerContacts.bind(this);
    this.populateHOSAlertsForLoadOneTrucks = this.populateHOSAlertsForLoadOneTrucks.bind(this);
    this.getCarriers = this.getCarriers.bind(this);
    this.getOffers = this.getOffers.bind(this);
    this.viewFit = this.viewFit.bind(this);
    this.getFedExRates = this.getFedExRates.bind(this);
    this.getDHLRates = this.getDHLRates.bind(this);
    this.getEShippingRates = this.getEShippingRates.bind(this);
    this.getUPSRates = this.getUPSRates.bind(this);
    this.fetchWinPercentageChart = this.fetchWinPercentageChart.bind(this);
    this.openLogs = this.openLogs.bind(this);
    this.openStatusUpdate = this.openStatusUpdate.bind(this);
    this.reply = this.reply.bind(this);
    this.copyEmailTemplate = this.copyEmailTemplate.bind(this);
    this.copyEmailTemplateCarrier = this.copyEmailTemplateCarrier.bind(this);
    this.emailCarrier = this.emailCarrier.bind(this);
    this.awardCarrier = this.awardCarrier.bind(this);
    this.rejectAllCarriers = this.rejectAllCarriers.bind(this);
    this.openDeadhead = this.openDeadhead.bind(this);
    this.selectCarrierOffer = this.selectCarrierOffer.bind(this);
    this.openReviews = this.openReviews.bind(this);
    this.clickQuote = this.clickQuote.bind(this);
    this.toggleCollapse = this.toggleCollapse.bind(this);
    this.onLayoutChange = this.onLayoutChange.bind(this);
    this.toggleEditLayout = this.toggleEditLayout.bind(this);
    this.resetLayout = this.resetLayout.bind(this);
  }

  render() {
    const Layout = (this.state.Collapse && this.state.EditLayout == false)
      ? this.state.Layout.filter(x => !['quote', 'map'].includes(x.i))
      : this.state.Layout;

    return (
      <React.Fragment>
        <Title string={this.state.BillingCustomerID > 0 ? this.state.BillingCustomerNumber : this.state.AuthorizationCustomerID > 0 ? this.state.AuthorizationCustomerNumber : 'Quote'} />
        <br />
        {this.state.ShowAuditLogs && <AuditLogs QuoteID={this.state.QuoteID} CloseDialog={() => this.setState({ ShowAuditLogs: false })} />}
        {this.state.ShowFrontReply && <FrontReply
          Amount={this.state.BidAmount}
          QuoteID={this.state.QuoteID}
          ThreadID={this.state.ThreadID}
          SelectedCarrierOfferID={this.state.SelectedCarrierOfferID}
          Refresh={() => {
            this.setState({ ShowFrontReply: false, BidAmount: undefined }, () => this.getQuote(this.state.QuoteID));
          }}
          CloseDialog={() => this.setState({ ShowFrontReply: false, BidAmount: undefined, SelectedCarrierOfferID: undefined })}
        />}
        {this.state.ShowPersonalizedUrl && <PersonalizedUrl CreateUrl={this.createPersonalizedUrl} CloseDialog={() => this.setState({ ShowPersonalizedUrl: false })} />}
        {this.state.ShowStatusUpdate && <ComposeEmail
          Link={({ Link: `/api/Track/StatusUpdateEmail/${this.state.OrderID}` } as ILink)}
          CloseDialog={() => this.setState({ ShowStatusUpdate: false })}
        />}
        {this.state.DuplicateQuotes.length > 0 && <Window title="Possible Duplicates" onClose={() => this.setState({ DuplicateQuotes: [] })} width={800}>
          <Grid
            data={this.state.DuplicateQuotes}
            scrollable='none'
            onRowClick={(e) => window.location.href = `/Quote/Index/${e.dataItem.QuoteID}`}
          >
            <Column field="QuoteID" title="Quote #" cell={(props) => <td style={{ color: '#007bff' }} role="button">{props.dataItem.QuoteID}</td>} />
            <Column field="ThreadID" title="Front" cell={TheadIDCell} />
            <Column field="VehicleTypeName" title="Vehicle Type" />
            <Column field="TotalCharges" title="Total Charges" format='{0:c}' />
            <Column field="CreatedDateTime" title="Created Date" cell={DateCell} />
            <Column field="CreatedByUserName" title="Created By" />
            <Column field="SourceName" title="Source" />
          </Grid>
          <WindowActionsBar>
            <Button onClick={() => this.setState({ DuplicateQuotes: [] })}>Close</Button>
            <Button themeColor='warning' disabled={this.state.IsSaving} onClick={this.addQuote}>
              Create Quote Anyway
            </Button>
          </WindowActionsBar>
        </Window>}
        {this.state.OrderModNumDiff > 0 && <div className="alert alert-warning" role="alert">
          <strong>Warning!</strong> Order has been updated {this.state.OrderModNumDiff === 1 ? 'once' : this.state.OrderModNumDiff === 2 ? 'twice' : `${this.state.OrderModNumDiff} times`} since this quote was saved. <Link to={`/Order/${this.state.OrderID}`}>View Order</Link> or <a href="#" onClick={(e) => { e.preventDefault(); this.updateQuoteFromOrder(); }}>Update Quote from Order</a>.
        </div>}
        <div className="form-row">
          <div className="form-group col-md-2">
            <div className="input-group">
              <CustomerInput
                focus
                onCreditHold={false}
                placeholder='Billing Company'
                CustomerID={this.state.BillingCustomerID}
                CustomerNumber={this.state.BillingCustomerNumber}
                onChange={(e) => {
                  this.updateQuote({ BillingCustomerID: e.CustomerID, BillingCustomerNumber: e.CustomerNumber }, "CUSTOMER");
                }}
              />
            </div>
          </div>
          <div className="form-group col-md-2">
            <div className="input-group">
              <CustomerInput
                placeholder='Authorization Company'
                CustomerID={this.state.AuthorizationCustomerID}
                CustomerNumber={this.state.AuthorizationCustomerNumber}
                onChange={(e) => {
                  if (e.CustomerID) {
                    this.populateAuthorizationCustomerContacts(e.CustomerID);
                  }
                  this.updateQuote({ AuthorizationCustomerID: e.CustomerID, AuthorizationCustomerNumber: e.CustomerNumber, AuthorizationCustomerContactID: 0, AuthorizationCustomerContacts: [] }, "AUTHORIZATION_CUSTOMER");
                }}
              />
            </div>
          </div>
          <div className="col-md-2">
            <div className="input-group">
              <CustomerContactInput
                CustomerID={this.state.AuthorizationCustomerID}
                CustomerContactID={this.state.AuthorizationCustomerContactID}
                CustomerContacts={this.state.AuthorizationCustomerContacts}
                onChange={(e) => {
                  if (e.CustomerID) {
                    this.populateAuthorizationCustomerContacts(e.CustomerID);
                    this.updateQuote({ AuthorizationCustomerID: e.CustomerID, AuthorizationCustomerNumber: e.CustomerNumber, AuthorizationCustomerContactID: e.CustomerContactID, AuthorizationCustomerContacts: [] }, "AUTHORIZATION_CUSTOMER");
                  } else {
                    this.updateQuote({ AuthorizationCustomerContactID: e.CustomerContactID }, "CUSTOMERCONTACT");
                  }
                }}
              />
            </div>
          </div>
          <div className="col-md-2">
            <div className="input-group">
              <input
                type="text"
                maxLength={15}
                className="form-control"
                placeholder="Front ID"
                value={this.state.ThreadID}
                onFocus={(e) => e.target.select()}
                onChange={(e) => this.updateQuote({ ThreadID: e.target.value }, "THREAD")}
              />
              {this.state.ThreadID && <div className="input-group-append">
                <button className="btn btn-outline-secondary" type="button" onClick={this.openThread}>Open</button>
              </div>}
            </div>
          </div>
          <div className="col-md-4 text-right">
            {this.state.QuoteID > 0 && <>
              &nbsp;&nbsp;
              <div className="dropdown d-inline-block">
                <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownActions" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Quote # {this.state.QuoteID}
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownActions">
                  <button className="dropdown-item" onClick={this.openLogs}>Logs</button>
                  <button className="dropdown-item" type="button" onClick={this.markTemplate}>Mark as {this.state.IsTemplate ? 'NOT' : ''} Template</button>
                  <div className="dropdown-divider"></div>
                  <a className="dropdown-item disabled" href="#">{this.state.CreatedByUserName}</a>
                </div>
              </div>
            </>}
            {this.state.QuoteID === 0 && <div className="btn-group">
              <button onClick={this.addQuote} className="btn btn-primary">Create New Quote</button>
              <button type="button" className="btn btn-primary dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span className="sr-only">Toggle Dropdown</span>
              </button>
              <div className="dropdown-menu">
                <button className="dropdown-item" type="button" onClick={this.openPersonalizedUrl}>Create Personalized URL</button>
                <button className="dropdown-item" type="button" onClick={this.reserveQuote}>Reserve Quote #</button>
              </div>
            </div>}
            {this.state.Unassigned && <>
              &nbsp;&nbsp;
              <Button themeColor="primary" onClick={this.selfAssign}>Self Assign</Button>
            </>}
            {this.state.QuoteID === 0 && this.state.PreviousQuoteID > 0 && <React.Fragment>
              &nbsp;&nbsp;
              <div className="dropdown d-inline-block">
                <button className="btn btn-warning dropdown-toggle" type="button" id="dropdownUpdateQuote" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Update Quote # {this.state.PreviousQuoteID}
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownUpdateQuote">
                  <button className="dropdown-item" type="button" onClick={() => this.reAddQuote(true)}>Clear Offers</button>
                  <button className="dropdown-item" type="button" onClick={() => this.reAddQuote(false)}>Keep Offers</button>
                </div>
              </div>
            </React.Fragment>}
            {this.state.OrderID > 0 && <React.Fragment>
              &nbsp;&nbsp;
              <div className="dropdown d-inline-block">
                <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownUpdateOrder" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Pro # {this.state.OrderNumber || 'N/A'}
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownUpdateOrder">
                  <button className="dropdown-item" onClick={this.openOrder}>View Order</button>
                  <button className="dropdown-item" onClick={this.updateQuoteFromOrder}>Update from Order</button>
                  {this.state.OrderStatus === 100 && <button className="dropdown-item" onClick={this.dispatchOrder}>Dispatch</button>}
                  <button className="dropdown-item" onClick={this.openStatusUpdate}>Send Status Update</button>
                </div>
              </div>
            </React.Fragment>}
            {this.state.QuoteID > 0 && !this.state.OrderID && <React.Fragment>
              &nbsp;&nbsp;
              {this.state.Unassigned == false && <CreateOrder quoteId={this.state.QuoteID} onCreated={(data) => this.setState({ OrderID: data.OrderID, OrderNumber: data.OrderNumber, OrderStatus: data.OrderStatus })} />}
            </React.Fragment>}

            {this.state.QuoteID > 0 && this.state.Unassigned == false && <React.Fragment>
              &nbsp;&nbsp;
              <SplitButton
                text="Reply/Quote"
                themeColor="primary"
                onButtonClick={this.reply}
                onItemClick={() => this.copyEmailTemplate()}
                items={['Copy Email']}
              />
            </React.Fragment>}

            {this.state.QuoteID > 0 && this.state.Unassigned == false && this.state.OrderStatus <= 100 && <React.Fragment>
              &nbsp;&nbsp;
              <div className="dropdown d-inline-block">
                <button
                  type="button"
                  id="dropdownPostQuote"
                  className="btn btn-primary dropdown-toggle"
                  //data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                  onClick={() => {
                    this.setState((prevState) => ({ ShowOrderPostingDropdown: !prevState.ShowOrderPostingDropdown }), () => {
                      if (this.state.ShowOrderPostingDropdown) {
                        this.postLoadOption.current?.getPostedLoadInfo();
                      }
                    });
                  }}
                >
                  Post
                </button>
                <div className={`dropdown-menu dropdown-menu-right${this.state.ShowOrderPostingDropdown ? ' show' : ''}`}
                  aria-labelledby="dropdownPostQuote">
                  <PostLoadOption
                    ref={this.postLoadOption}
                    quoteId={this.state.QuoteID}
                    onPostRequest={
                      (deletePosting: boolean, loadPosting: LoadPosting) => {
                        this.setState({ CurrentLoadPosting: loadPosting }, () => {
                          this.toggleOrderPostingDialog(false, deletePosting)
                        })
                      }
                    }
                    onClose={() => { this.setState({ ShowOrderPostingDropdown: false }) }}
                  />
                </div>
              </div>
            </React.Fragment>}

            &nbsp;&nbsp;
            <Button onClick={this.toggleCollapse} svgIcon={this.state.Collapse ? chevronDownIcon : chevronUpIcon} />
          </div>
        </div>
        {this.state.Collapse == false && <React.Fragment>
          {this.renderInstructions()}
          <div className="form-row">
            <div className="col-md-9">
              {this.state.Stops.map((stop, index) => {
                return <EnterStop
                  key={index}
                  stop={stop}
                  index={index}
                  location={this.state.Locations.find(x => x.Sequence == index + 1)}
                  onChange={(data, changeType) => this.updateStop(data, changeType, index)}
                  addPair={() => this.addPair(index + 1)}
                  addStop={(stopType) => this.addStop(index, stopType)}
                  remove={() => this.removeStop(index)}
                >
                  <div className="form-row">
                    <div className="col-md-9">
                      {this.state.TransitData && this.state.TransitData.Locations[index - 1] &&
                        <StopTransitData
                          stop={stop}
                          index={index}
                          last={index === this.state.Stops.length - 1}
                          distance={this.state.TransitData.Distance}
                          tolls={this.state.TransitData.Tolls}
                          location={this.state.TransitData.Locations[index - 1]}
                          onChange={(data, changeType) => this.updateStop(data, changeType, index)} />}
                    </div>
                    {stop.CustomerID > 0 && this.state.AvgWaitTimeData[index] && this.state.AvgWaitTimeData[index].Formatted !== "00:00" &&
                      <div className="col-md-3">
                        <label style={{ paddingTop: '.375rem' }} className="font-weight-bold">
                          Avg. Wait Time: {this.state.AvgWaitTimeData[index].Formatted}
                        </label>
                      </div>}
                  </div>
                </EnterStop>
              })}
            </div>
            <div className="col-md-3">
              <textarea
                maxLength={240}
                className="form-control mb-2"
                style={{ height: '136px' }}
                placeholder="Note"
                value={this.state.Note}
                onChange={(e) => this.updateQuote({ Note: e.target.value }, "NOTE")} />
              <input
                type="text"
                className="form-control"
                placeholder="Route Name"
                maxLength={50}
                value={this.state.RouteName}
                onChange={(e) => this.updateQuote({ RouteName: e.target.value }, "ROUTENAME")} />
            </div>
          </div>
          <div className="form-row">
            <div className="col-md-3">
              {this.state.Stops.length > 2 ? <>
                <ButtonGroup>
                  <Button themeColor='primary' onClick={() => this.addStop(0, QuoteStopType.Delivery)}>Add Delivery</Button>
                  <Button onClick={() => this.removeStop(this.state.Stops.length - 1)}>Remove</Button>
                </ButtonGroup>
                &nbsp;&nbsp;
                <DropDownButton items={["All Stops", "Intermediate Stops"]} onItemClick={(e) => this.optimizeStops(e.itemIndex + 1)} text="Optimize" />
              </> : <Button themeColor='primary' onClick={() => this.addStop(0, QuoteStopType.Delivery)}>Add Delivery</Button>}
            </div>
            <div className="col-md-3">
              <label className="my-1 mr-2">Deadhead Miles</label>
              <NumericTextBox
                value={this.state.DeadheadMiles}
                min={0} spinners={false}
                format="n0"
                width={148}
                onChange={(e) => this.setState(prevState => ({ DeadheadMiles: e.value || 0, PendingChangeTypes: [...prevState.PendingChangeTypes, "DEADHEADMILES"] }), () => this.updatePanelsDebounced())} />
            </div>
            <div className="col-md-3">
              <label className="my-1 mr-2">&nbsp;&nbsp;Remaining HOS</label>
              <TimePicker
                format="HH:mm"
                formatPlaceholder="formatPattern"
                nowButton={false}
                value={this.state.RemainingHoursofService}
                width={148}
                onChange={(e) => this.setState(prevState => ({ RemainingHoursofService: e.value, PendingChangeTypes: [...prevState.PendingChangeTypes, "REMAININGHOURSOFSERVICE"] }), () => this.updatePanelsDebounced())} />
            </div>
            <div className="col-md-3">
              <label className="my-1 mr-2">&nbsp;&nbsp;Override Miles</label>
              <NumericTextBox
                value={this.state.OverrideDistance}
                min={0} spinners={false}
                format="n0"
                width={148}
                onChange={(e) => this.updateQuote({ OverrideDistance: e.value || 0 }, "OVERRIDEDISTANCE")} />
            </div>
          </div>
          <br />
          <div className="form-row">
            <div className="form-group col-md-3">
              <div className="input-group">
                <BrokerInput
                  BrokerID={this.state.BrokerID}
                  BrokerName={this.state.BrokerName}
                  onChange={(e) => this.updateQuote({ BrokerID: e.BrokerID, BrokerName: e.BrokerName }, "BROKER")}
                />
              </div>
            </div>
            <div className="form-group col-md-3">
              <div className="input-group">
                <DropDownList
                  defaultValue={0}
                  value={this.bookingSources.find(x => x.value == this.state.BookingSource)}
                  popupSettings={{ animate: false }}
                  data={this.bookingSources}
                  textField="text"
                  onChange={this.handleBookingSourceChange} />
              </div>
            </div>
          </div>
          {this.state.Freight.map((freight, index) => {
            return <EnterFreight
              key={index}
              index={index}
              freight={freight}
              onChange={(data, changeType) => this.updateFreight(data, changeType, index)}
              add={(copy) => this.addFreight(index, copy)}
              copy={(above) => this.copyFreight(index, above)}
              remove={() => this.removeFreight(index)}
            />
          })}
          <div className="form-row">
            <div className="col-md-1 offset-md-2">
              <div className="form-check">
                <Checkbox id="hazmat" className="form-check-input" defaultValue={this.state.HazMat} value={this.state.HazMat.toString()} handleCheckboxChange={this.toggleHazMat} />
                <label className="form-check-label" htmlFor="hazmat">HazMat</label>
              </div>
            </div>
            <div className="col-md-1">
              <div className="form-check">
                <Checkbox id="dockhigh" className="form-check-input" defaultValue={this.state.DockHigh} value={this.state.DockHigh.toString()} handleCheckboxChange={this.toggleDockHigh} />
                <label className="form-check-label" htmlFor="dockhigh">Dock High</label>
              </div>
            </div>
            <div className="col-md-1">
              <div className="form-check">
                <Checkbox id="liftGate" className="form-check-input" defaultValue={this.state.LiftGate} value={this.state.LiftGate.toString()} handleCheckboxChange={this.toggleLiftGate} />
                <label className="form-check-label" htmlFor="liftGate">Lift Gate</label>
              </div>
            </div>
            <div className="col-md-1">
              <div className="form-check">
                <Checkbox id="airRide" className="form-check-input" defaultValue={this.state.AirRide} value={this.state.AirRide.toString()} handleCheckboxChange={this.toggleAirRide} />
                <label className="form-check-label" htmlFor="airRide">Air Ride</label>
              </div>
            </div>
            <div className="col-md-1">
              <div className="form-check">
                <Checkbox id="fast" className="form-check-input" defaultValue={this.state.FAST} value={this.state.FAST.toString()} handleCheckboxChange={this.toggleFAST} />
                <label className="form-check-label" htmlFor="fast">FAST</label>
              </div>
            </div>
            <div className="col-md-1">
              <div className="form-check">
                <Checkbox id="tsa" className="form-check-input" defaultValue={this.state.TSA} value={this.state.TSA.toString()} handleCheckboxChange={this.toggleTSA} />
                <label className="form-check-label" htmlFor="tsa">TSA</label>
              </div>
            </div>
            <div className="col-md-4">
              <DropDownList
                defaultValue={0}
                value={vehicleTypes.find(x => x.value == this.state.VehicleTypeID)}
                popupSettings={{ height: 700, animate: false }}
                data={vehicleTypes}
                textField="text"
                onChange={this.handleVehicleTypeChange}
              />
            </div>
          </div>
          <br />
        </React.Fragment>}
        {this.state.CarrierReviewPhone && <CarrierReviews
          CloseDialog={() => this.setState({ CarrierReviewPhone: null })}
          Name={this.state.CarrierReviewName} PhoneNumber={this.state.CarrierReviewPhone} />}

        <ReactGridLayout
          cols={12}
          rowHeight={60}
          className="layout"
          style={{ marginLeft: -10, marginRight: -10 }}
          layout={Layout}
          onLayoutChange={this.onLayoutChange}
        >
          {this.renderPanels()}
        </ReactGridLayout>

        <div className="d-flex justify-content-center">
          {this.state.EditLayout && <><Button onClick={this.resetLayout}>Reset Layout</Button>&nbsp;&nbsp;</>}
          <Button onClick={this.toggleEditLayout}>{this.state.EditLayout ? 'Done' : 'Edit Layout'}</Button>
        </div>

        {this.state.QuoteEmailTemplate &&
          <QuoteEmailTemplate
            QuoteID={this.state.QuoteID}
            BillingCustomerNumber={this.state.BillingCustomerNumber}
            TransitData={this.state.TransitData}
            QuoteData={this.state.QuoteData}
            Stops={this.state.Stops}
            Locations={this.state.Locations}
            Freight={this.state.Freight}
            VehicleTypeID={this.state.VehicleTypeID}
            Vehicle={this.state.QuoteEmailVehicle}
            CloseDialog={() => this.setState({ QuoteEmailTemplate: false, QuoteEmailVehicle: null })}
          />}
        {this.state.QuoteEmailTemplateCarrier &&
          <QuoteEmailTemplateCarrier
            QuoteID={this.state.QuoteID}
            Offer={this.state.QuoteEmailTemplateCarrier}
            TransitData={this.state.TransitData}
            Stops={this.state.Stops}
            Locations={this.state.Locations}
            Freight={this.state.Freight}
            VehicleTypeID={this.state.VehicleTypeID}
            CloseDialog={() => this.setState({ QuoteEmailTemplateCarrier: null })}
          />}
        <div id="snackbar" className={this.state.SnackBarText.length > 0 ? 'show' : ''}>{this.state.SnackBarText}</div>

        {this.state.ShowOrderPostingAllInRateDialog && <Dialog title={"All-In-Rate"} onClose={() => { this.toggleOrderPostingDialog(false); }}>
          <p style={{ margin: "25px", textAlign: "center" }}>
            <NumericTextBox
              ref={this.orderPostingAllInRateInput}
              value={this.state.CurrentLoadPosting.AllInRate || 0}
              min={0}
              spinners={false}
              format="n2"
              onChange={(e) => this.setState({ CurrentLoadPosting: { ...this.state.CurrentLoadPosting, AllInRate: e.value ?? 0 } })}
            />
            <div className="mt-2" dangerouslySetInnerHTML={{
              __html: (this.state.CurrentLoadPosting.LoadBoard !== LoadBoard.FullCircle && this.state.CurrentLoadPosting.LoadBoard !== LoadBoard.Sylectus) ?
                "This load board option <u>requires</u> an all-in-rate" :
                "An all-in-rate is <u>not required</u>"
            }} ></div>
          </p>
          <DialogActionsBar>
            <Button onClick={() => this.toggleOrderPostingDialog(false)}>Cancel</Button>
            <Button themeColor="primary"
              disabled={(this.state.CurrentLoadPosting.LoadBoard !== LoadBoard.FullCircle && this.state.CurrentLoadPosting.LoadBoard !== LoadBoard.Sylectus) &&
                (this.state.CurrentLoadPosting.AllInRate === 0 || this.state.CurrentLoadPosting.AllInRate === undefined)}
              onClick={() => this.toggleOrderPostingDialog(true)}>Post</Button>
          </DialogActionsBar>
        </Dialog>}

      </React.Fragment>
    );
  }

  private toggleOrderPostingDialog(postOrder: boolean, deletePostOrder: boolean = false) {
    if (deletePostOrder) {
      this.setState({
        ShowOrderPostingDropdown: false
      }, () => {
        this.postLoadOption.current.postquote(this.state.CurrentLoadPosting, deletePostOrder);
      });
    } else {
      this.setState(prevState => ({
        ShowOrderPostingAllInRateDialog: !prevState.ShowOrderPostingAllInRateDialog,
        ShowOrderPostingDropdown: false,
      }), () => {
        if (postOrder && this.postLoadOption.current) {
          this.postLoadOption.current.postquote(this.state.CurrentLoadPosting);
        } else {
          if (this.orderPostingAllInRateInput.current) {
            this.orderPostingAllInRateInput.current.focus();
            if (this.orderPostingAllInRateInput.current.value === 0) {
              this.orderPostingAllInRateInput.current.element.selectionStart = 0;
            }
          }
        }
      });
    }
  }

  private renderPanels(): React.ReactNode[] {
    if (this.state.EditLayout) {
      return [
        <div key="quote" className="bg-success d-flex justify-content-center"><h1 className="text-white">Rate Amount</h1></div>,
        <div key="map" className="bg-primary d-flex justify-content-center"><h1 className="text-white">Map</h1></div>,
        <div key="vehicles" className="bg-warning d-flex justify-content-center"><h1 className="text-white">Vehicle Listing</h1></div>,
        <div key="recent" className="bg-danger d-flex justify-content-center"><h1 className="text-white">Recent Quotes Listing</h1></div>
      ]
    }
    const panels = [<div key="quote">
      <QuotePanel
        ref={this.quotePanel}
        QuoteID={this.state.QuoteID}
        data={this.state.QuoteData}
        smartbidData={this.state.SmartbidData}
        winPercentageData={this.state.WinPercentageData}
        loading={this.state.QuoteDataIsLoading}
        outdated={this.state.QuoteDataOutdated}
        smartbidLoading={this.state.SmartbidDataIsLoading}
        tolls={this.state.TransitData ? this.state.TransitData.Tolls : 0}
        distance={this.state.TransitData ? this.state.TransitData.Distance : 0}
        fuelRate={this.state.FuelRate}
        addCharge={this.addCharge}
        updateCharge={this.updateCharge}
        removeCharge={this.removeCharge}
        replaceCharges={this.replaceCharges}
        fetchWinPercentageChart={this.fetchWinPercentageChart}
        recalculateRate={() => {
          this.setState(prevState => ({
            QuoteDataOutdated: false,
            QuoteData: null,
            PendingChangeTypes: [...prevState.PendingChangeTypes, "RATE"]
          }), () => this.updatePanels());
        }}
      />
    </div>,
    <div key="map">
      {this.state.Locations.length > 1 && <MapPanel
        vehicleTypeId={this.state.VehicleTypeID}
        locations={this.state.Locations.map(x => x.Location)}
        version={this.state.PCMilerVersion}
      />}
    </div>,
    <div key="vehicles" style={{ overflowX: 'auto' }}>
      <VehiclesPanel
        ref={this.vehiclesPanel}
        quoteID={this.state.QuoteID}
        orderID={this.state.OrderID}
        orderStatus={this.state.OrderStatus}
        note={this.state.Note}
        data={this.state.VehicleData}
        loading={this.state.VehicleDataIsLoading}
        loadingOffers={this.state.OfferDataIsLoading}
        defaultFilters={this.state.DefaultVehicleFilters}
        sylectusData={this.state.AllianceVehicleData}
        carrierData={this.state.CarrierData}
        offerData={this.state.OfferData}
        fedExData={this.state.FedExRateData}
        dhlData={this.state.DHLRateData}
        eShippingData={this.state.EShippingRateData}
        upsData={this.state.UPSRateData}
        dispatch={this.dispatchQuote}
        cancel={this.cancelQuote}
        offer={this.offerQuote}
        reoffer={this.reOfferQuote}
        reserve={this.reserve}
        transfer={this.transfer}
        release={this.release}
        turndown={this.turndown}
        distance={this.state.Distance}
        updateDistance={this.updateDistance}
        callLoadOneTrucks={this.callLoadOneTrucks}
        callSylectus={this.callSylectus}
        getCarriers={this.getCarriers}
        getOffers={this.getOffers}
        getFedExRates={this.getFedExRates}
        getDHLRates={this.getDHLRates}
        getEShippingRates={this.getEShippingRates}
        getUPSRates={this.getUPSRates}
        openReviews={this.openReviews}
        copyEmail={this.copyEmailTemplate}
        viewFit={this.viewFit}
        emailCarrier={this.emailCarrier}
        reply={this.reply}
        copyCarrierEmailTemplate={this.copyEmailTemplateCarrier}
        awardCarrier={this.awardCarrier}
        rejectAllCarriers={this.rejectAllCarriers}
        openDeadhead={this.openDeadhead}
        selectCarrierOffer={this.selectCarrierOffer}
        rate={this.state.QuoteData ? this.state.QuoteData.LineHaulAmount + this.state.QuoteData.AccessorialAmount : 0}
        fuel={this.state.QuoteData ? this.state.QuoteData.FuelAmount : 0}
        bonus={this.state.QuoteData ? this.state.QuoteData.BonusAmount : 0}
        margin={this.state.Margin}
      />
    </div>,
    <div key="recent" style={{ overflowX: 'auto' }}>
      <RecentPanel
        ref={this.recentPanel}
        quoteID={this.state.QuoteID}
        billingCustomerID={this.state.BillingCustomerID}
        vehicleTypeID={this.state.VehicleTypeID}
        locations={this.state.Locations}
      />
    </div>];

    return this.state.Collapse ? panels.filter(x => !['quote', 'map'].includes(x.key as string)) : panels;
  }

  private renderInstructions() {
    if (this.state.BillingInstructions.length === 0 && this.state.AuthorizationInstructions.length === 0) return null;
    return <p>
      {this.state.BillingInstructions.map((instruction, index) => {
        return <React.Fragment key={index}>
          <span {...this.instructionColor(instruction.Display)}>{this.state.BillingCustomerNumber}: {instruction.Instruction}</span>
          <br />
        </React.Fragment>;
      })}
      {this.state.AuthorizationInstructions.map((instruction, index) => {
        return <React.Fragment key={index}>
          <span {...this.instructionColor(instruction.Display)}>{this.state.AuthorizationCustomerNumber}: {instruction.Instruction}</span>
          <br />
        </React.Fragment>;
      })}
    </p>
  }

  public componentDidMount() {
    const quoteId = parseInt(this.props.match.params.quoteId) || 0;
    if (quoteId > 0) {
      this.getQuote(quoteId);
    } else {
      const urlParams = new URLSearchParams(window.location.search);
      const frontIdParam = urlParams.get('frontId');
      if (frontIdParam != null) {
        this.setState({ ThreadID: frontIdParam });

        fetchApi(`/api/BlindBidQuote/BillToFromFront/${frontIdParam}`)
          .then((data: { BillingCustomerID: number, BillingCustomerNumber: string }) => {
            if (data.BillingCustomerID > 0) {
              this.setState({
                BillingCustomerID: data.BillingCustomerID,
                BillingCustomerNumber: data.BillingCustomerNumber
              });
            }
          })
          .catch(() => { });
      }
    }
    this.setQuoteDefaults();
    this.createSignalRConnection();
  }

  public componentWillUnmount() {
    this.reconnectSignalr = false;
    if (this.connection) {
      this.connection.stop();
    }
  }

  public componentWillReceiveProps(nextProps: Props) {
    // This method runs when incoming props (e.g., route params) change

    let quoteId = parseInt(nextProps.match.params.quoteId) || 0;

    if (quoteId && (this.state.QuoteID != quoteId)) {
      this.getQuote(quoteId);
    }
  }

  public componentDidUpdate(prevProps: Props, prevState: State) {
    // Handles clicking Quote NavBar
    if (this.props.location.key !== prevProps.location.key && !this.props.match.params.quoteId) {
      const resetData = this.emptyState();
      this.setState({ ...resetData }, () => { this.resetRecentQuotes(); });
      return;
    }
    // Handles updating Instructions
    if (this.state.BillingCustomerID > 0 && this.state.BillingCustomerID !== prevState.BillingCustomerID) {
      this.updateBillingSpecialInstructions(this.state.BillingCustomerID);
    } else if (this.state.BillingCustomerID == 0 && this.state.BillingInstructions.length > 0) {
      this.setState({ BillingInstructions: [] });
    }
    if (this.state.AuthorizationCustomerID > 0 && this.state.AuthorizationCustomerID !== prevState.AuthorizationCustomerID) {
      this.updateAuthorizationSpecialInstructions(this.state.AuthorizationCustomerID);
    } else if (this.state.AuthorizationCustomerID == 0 && this.state.AuthorizationInstructions.length > 0) {
      this.setState({ AuthorizationInstructions: [] });
    }
  }

  private instructionColor(display: number) {
    switch (display) {
      case 82:
        return { className: "text-danger" };
      case 71:
        return { className: "text-success" };
      case 89:
        return { className: "text-warning" };
      case 66:
        return { className: "text-primary" };
    }
    return null;
  }

  private emptyState(): State {
    const Layout = JSON.parse(localStorage.getItem('Quote-Layout')) ?? this.defaultLayout;

    const urlParams = new URLSearchParams(window.location.search);
    const vehicleFilters = urlParams.get('vehicleFilters')?.split(',') ?? [];

    return {
      QuoteID: 0,
      OrderID: null,
      OrderNumber: null,
      OrderStatus: null,
      OrderModNumDiff: null,
      Unassigned: false,
      ThreadID: '',
      RouteName: '',
      CreatedByUserName: '',
      IsTemplate: false,
      PreviousQuoteID: 0,
      Stops: [{
        Sequence: 1,
        StopType: QuoteStopType.Pickup,
        StopVerb: QuoteStopVerb.Protect,
        CustomerID: 0,
        CustomerNumber: '',
        Location: '',
        DateTime: Moment().add(2, "hours").format("MM/DD/YYYY HH:mm"),
      }, {
        Sequence: 2,
        StopType: QuoteStopType.Delivery,
        StopVerb: QuoteStopVerb.Protect,
        CustomerID: 0,
        CustomerNumber: '',
        Location: '',
        DateTime: Moment().add(4, "hours").format("MM/DD/YYYY HH:mm"),
      }],
      Freight: [{
        PickupStopSequence: 1,
        DeliveryStopSequence: 2,
        Weight: 0,
        WeightUnitOfMeasure: 0,
        Pieces: 0,
        Length: 0,
        Width: 0,
        Height: 0,
        DimsUnitOfMeasure: 0,
        Stackable: false,
        StackableLimit: 0,
        Rotatable: false,
      }],
      TSA: false,
      FAST: false,
      HazMat: false,
      DockHigh: false,
      LiftGate: false,
      AirRide: false,
      OverrideDistance: 0,
      VehicleTypeID: 0,
      CommodityTypeID: 0,
      BillingCustomerID: 0,
      BillingCustomerNumber: '',
      BillingInstructions: [],
      PCMilerVersion: null,
      AuthorizationCustomerID: 0,
      AuthorizationCustomerNumber: '',
      AuthorizationInstructions: [],
      AuthorizationCustomerContactID: 0,
      AuthorizationCustomerContacts: [],
      BrokerID: 0,
      BrokerName: '',
      BookingSource: 0,

      Note: '',

      DeadheadMiles: 0,
      RemainingHoursofService: new Date(0, 0, 0, 11, 0, 0),
      AvgWaitTimeData: [],
      AvgWaitTimeDataIsLoading: false,
      TransitData: null,
      TransitDataIsLoading: false,
      QuoteData: null,
      QuoteDataIsLoading: false,
      QuoteDataOutdated: false,
      SmartbidData: null,
      SmartbidDataIsLoading: false,
      SmartbidDataUsed: false,
      SmartbidRateUpDown: null,
      SmartbidWinPercentagePrevious: null,
      SmartbidWinPercentageCurrent: null,
      WinPercentageData: null,
      VehicleData: null,
      VehicleDataIsLoading: false,
      DefaultVehicleFilters: vehicleFilters,
      Distance: 300,
      Margin: 0,
      AllianceVehicleData: null,
      CarrierData: null,
      OfferData: null,
      OfferDataIsLoading: false,
      FedExRateData: null,
      DHLRateData: null,
      EShippingRateData: null,
      UPSRateData: null,
      FuelRate: 0,
      BidAmount: undefined,
      SelectedCarrierOfferID: undefined,

      QuoteEmailTemplate: false,
      QuoteEmailVehicle: null,
      QuoteEmailTemplateCarrier: null,
      ShowAuditLogs: false,
      ShowStatusUpdate: false,
      ShowFrontReply: false,
      ShowPersonalizedUrl: false,
      SnackBarText: '',
      CarrierReviewName: '',
      CarrierReviewPhone: '',

      ValidationRequestHash: '',
      ValidationRequestHashTransit: '',
      ValidationRequestHashRate: '',
      ValidationRequestHashFuelRate: '',
      ValidationRequestHashVehicles: '',
      ValidationRequestHashExternalRates: '',
      ValidationRequestHashAvgWaitTimes: '',
      PendingChangeTypes: [],
      IsSaving: false,
      DuplicateQuotes: [],

      ShowOrderPostingDropdown: false,
      ShowOrderPostingAllInRateDialog: false,
      CurrentLoadPosting: null,

      Links: [],
      Locations: [],

      EditLayout: false,
      Layout,
      PrevLayout: [],
      Collapse: false,
    }
  }

  private getQuote(quoteId: number) {
    fetchApi(`/api/Quote/Get/${quoteId}`)
      .then((response: ViewModel) => {
        this.setState({
          QuoteID: response.QuoteID,
          OrderID: response.OrderID,
          PreviousQuoteID: 0,
          OrderNumber: response.OrderNumber,
          OrderStatus: response.OrderStatus,
          OrderModNumDiff: response.OrderModNumDiff,
          Unassigned: response.Unassigned,
          ThreadID: response.ThreadID,
          RouteName: response.RouteName,
          VehicleTypeID: response.VehicleTypeID,
          TSA: response.TSA,
          FAST: response.FAST,
          HazMat: response.HazMat,
          DockHigh: response.DockHigh,
          LiftGate: response.LiftGate,
          AirRide: response.AirRide,
          OverrideDistance: response.OverrideDistance,

          BillingCustomerID: response.BillingCustomerID,
          BillingCustomerNumber: response.BillingCustomerNumber,
          PCMilerVersion: response.PCMilerVersion,
          AuthorizationCustomerID: response.AuthorizationCustomerID,
          AuthorizationCustomerNumber: response.AuthorizationCustomerNumber,
          AuthorizationCustomerContactID: response.AuthorizationCustomerContactID,
          AuthorizationCustomerContacts: response.AuthorizationCustomerContacts,
          BrokerID: response.BrokerID,
          BrokerName: response.BrokerName,
          BookingSource: response.BookingSource,
          CreatedByUserName: response.CreatedByUserName,
          IsTemplate: response.IsTemplate,
          Distance: response.SearchRadius,
          Note: response.Note || '',
          OfferData: null,
          FedExRateData: null,
          DHLRateData: null,
          EShippingRateData: null,
          UPSRateData: null,
          Stops: response.Stops.map(x => {
            return {
              QuoteStopID: x.QuoteStopID,
              Sequence: x.Sequence,
              StopType: x.StopType,
              StopVerb: x.StopVerb,
              CustomerID: x.CustomerID,
              CustomerNumber: x.CustomerNumber,
              Location: x.Location,
              DateTime: Moment(x.DateTime).format("MM/DD/YYYY HH:mm"),
              LatestDateTime: x.StopVerb === QuoteStopVerb.Window ? Moment(x.LatestDateTime).format("MM/DD/YYYY HH:mm") : undefined,
            }
          }),
          Freight: response.Freight,
          QuoteData: {
            Amount: response.LineItems.reduce((a, b) => +a + +b.Amount, 0),
            LineHaulAmount: response.LineItems.filter(x => x.RateType == 70 || x.RateType == 76).reduce((a, b) => +a + +b.Amount, 0),
            FuelAmount: response.LineItems.filter(x => x.RateType == 85).reduce((a, b) => +a + +b.Amount, 0),
            AccessorialAmount: response.LineItems.filter(x => x.RateType == 65 && accessorialsNoDriverPay.indexOf(x.RateDescriptionID) == -1 && accessorialsFullDriverPay.indexOf(x.RateDescriptionID) == -1).reduce((a, b) => +a + +b.Amount, 0),
            BonusAmount: response.LineItems.filter(x => x.RateType == 65 && accessorialsFullDriverPay.indexOf(x.RateDescriptionID) != -1).reduce((a, b) => +a + +b.Amount, 0),
            RateComment: response.RateComment,
            LineItems: response.LineItems
          },
          WinPercentageData: null,
          QuoteDataOutdated: false,
          SmartbidData: null,
          SmartbidDataUsed: response.SmartbidUsed,
          PendingChangeTypes: ["QUOTE"],
          ShowOrderPostingDropdown: false,
        }, () => this.updatePanels());
      });
  }

  private getRecentQuotes() {
    if (this.recentPanel.current) {
      this.recentPanel.current.getRecentQuotes();
    }
  }

  private resetRecentQuotes() {
    if (this.recentPanel.current) {
      this.recentPanel.current.reset();
    }
  }

  private onLayoutChange(Layout: ReactGridLayout.Layout[]) {
    this.setState({ Layout });
  }

  private toggleEditLayout() {
    // Can't edit when collapsed
    if (this.state.Collapse) {
      this.toggleCollapse();
      setTimeout(this.toggleEditLayout);
      return;
    }
    const Layout = this.state.Layout.map(obj => ({ ...obj, static: this.state.EditLayout }));
    this.setState({ Layout, EditLayout: !this.state.EditLayout }, () => {
      if (this.state.EditLayout) {
        setTimeout(() => alert('Click and drag panels to rearrange, resize using the panels bottom right corner.  Click \'Done\' to save.'), 100);
      } else {
        localStorage.setItem("Quote-Layout", JSON.stringify(Layout));
      }
    });
  }

  private resetLayout() {
    localStorage.removeItem("Quote-Layout");
    this.setState({
      Layout: this.defaultLayout,
      PrevLayout: [],
      EditLayout: false,
    });
  }

  private setQuoteDefaults() {
    fetchApi("/api/Quote/Defaults")
      .then((response: {
        SearchRadius: number,
        Margin: number,
      }) => {
        this.setState({
          Distance: response.SearchRadius,
          Margin: response.Margin,
        });
      });
  }

  private createSignalRConnection() {

    // Check if mounted
    if (!this.reconnectSignalr) {
      return;
    }

    // Create Connection
    this.connection = new signalR.HubConnectionBuilder()
      .withAutomaticReconnect(new LoadOneReconnectPolicy())
      .withUrl("/quoteOffers")
      .configureLogging(signalR.LogLevel.Warning)
      .build();

    this.connection.onreconnected(() => {
      // TODO: Maybe?
    });

    // On New Notification
    this.connection.on('refreshVehicleQuoteOffers', (response: UpdateQuoteOfferCount) => {
      this.recentPanel.current.setQuoteOfferCount(response);
      if (response.QuoteID === this.state.QuoteID) {
        const data = {
          QuoteID: this.state.QuoteID,
          DateTime: this.state.Locations[0].DateTime,
          Locations: this.state.Locations.map(x => x.Location),
          VehicleTypeID: this.state.VehicleTypeID,
          Freight: this.state.Freight,
          DockHigh: this.state.DockHigh,
          LiftGate: this.state.LiftGate,
          AirRide: this.state.AirRide,
          HazMat: this.state.HazMat,
          FAST: this.state.FAST,
          TSA: this.state.TSA,
          Distance: this.state.Distance,
          Domestic: this.IsDomestic(),
          IncludeTriState: true,
        }
        const link = this.state.Links.find(x => x.Name === "Vehicles") as ILink;
        fetchApi(link.Link, data, link.Method)
          .then((data) => {
            this.setState({ VehicleData: data.Vehicles });
            if (this.vehiclesPanel.current) {
              this.vehiclesPanel.current.newQuoteOffer(1);
            }
          });

        // Refresh Offer Tab Data
        if (this.state.OfferData) {
          this.updateOffersDebounced();
        }
      }
    });

    this.connection.on('refreshCarrierQuoteOffers', (response: UpdateQuoteOfferCount) => {
      this.recentPanel.current.setQuoteOfferCount(response);
      if (response.QuoteID === this.state.QuoteID && (response.AcceptedOfferCount + response.DeclinedOfferCount + response.ExpiredOfferCount) > 0) {
        this.updateOffersDebounced();
      }
    });

    this.connection.on('refreshRecentQuotes', (response: { QuoteID: number }) => {
      this.getRecentQuotes();
      if (response.QuoteID === this.state.QuoteID) {
        if (this.state.OfferData) this.getOffers();
      }
    });

    this.connection.on('quoteAssigned', (response: UpdateQuoteAssigned) => {
      this.recentPanel.current.setQuoteAssigned(response);
      if (response.QuoteID === this.state.QuoteID) {
        if (this.state.Unassigned) this.setState({ Unassigned: false, CreatedByUserName: response.CreatedByUserName });
      }
    });

    this.connection.on('newQuote', () => {
      this.getRecentQuotes();
    });

    // connect
    this.connection.start()
      .catch((error) => console.error(error));
  }

  private updateQuote(data: any, changeType: string) {
    this.setState(prevState => ({
      ...data,
      QuoteID: 0,
      PreviousQuoteID: (prevState.QuoteID > 0) ? prevState.QuoteID : prevState.PreviousQuoteID,
      PendingChangeTypes: [...prevState.PendingChangeTypes, changeType],
    }), () => {
      this.updatePanelsDebounced();
    });
  }

  private addPair(index: number) {

    let sequence = index + 1;
    let stops = this.state.Stops;
    let locations = this.state.Locations;
    let freight = this.state.Freight;

    // Adjust all stop sequences
    stops.forEach(x => {
      if (x.Sequence >= sequence) x.Sequence = x.Sequence + 2;
    });
    locations.forEach(x => {
      if (x.Sequence >= sequence) x.Sequence = x.Sequence + 2;
    });

    // Adjust all freight sequences
    freight.forEach(x => {
      if (x.PickupStopSequence >= sequence) x.PickupStopSequence = x.PickupStopSequence + 2;
      if (x.DeliveryStopSequence >= sequence) x.DeliveryStopSequence = x.DeliveryStopSequence + 2;
    });

    // Add Stops
    stops.splice(index, 0, {
      Sequence: sequence,
      StopType: QuoteStopType.Pickup,
      StopVerb: QuoteStopVerb.Protect,
      CustomerID: 0,
      CustomerNumber: '',
      Location: '',
      DateTime: Moment().add(5, "hours").format("MM/DD/YYYY HH:mm"),
    });
    stops.splice(index + 1, 0, {
      Sequence: sequence + 1,
      StopType: QuoteStopType.Delivery,
      StopVerb: QuoteStopVerb.Protect,
      CustomerID: 0,
      CustomerNumber: '',
      Location: '',
      DateTime: Moment().add(10, "hours").format("MM/DD/YYYY HH:mm"),
    });

    // Add New Freight
    freight.push({
      PickupStopSequence: sequence,
      DeliveryStopSequence: sequence + 1,
      Weight: 0,
      WeightUnitOfMeasure: 0,
      Pieces: 0,
      Length: 0,
      Width: 0,
      Height: 0,
      DimsUnitOfMeasure: 0,
      Stackable: false,
      StackableLimit: 0,
      Rotatable: false,
    });

    // Order freight
    freight = freight.sort((a, b) => a.PickupStopSequence - b.PickupStopSequence || a.DeliveryStopSequence - b.DeliveryStopSequence);

    // Commit Changes
    this.setState(prevState => ({
      Stops: stops,
      Locations: locations,
      Freight: freight,
      TransitData: null,
      QuoteID: 0,
      PreviousQuoteID: (prevState.QuoteID > 0) ? prevState.QuoteID : prevState.PreviousQuoteID,
      PendingChangeTypes: [...prevState.PendingChangeTypes, "ADD_STOP"]
    }), () => {
      this.updatePanelsDebounced();
    });
  }

  private addStop(index: number, stopType: QuoteStopType) {

    let stops = this.state.Stops;
    let locations = this.state.Locations;
    let freight = this.state.Freight;
    const adjustSequence = stopType === QuoteStopType.Delivery ? index + 1 : index;

    // Adjust all stop sequences
    stops.forEach(x => {
      if (x.Sequence > adjustSequence) x.Sequence++;
    });
    locations.forEach(x => {
      if (x.Sequence > adjustSequence) x.Sequence++;
    });

    // Adjust all freight sequences
    freight.forEach(x => {
      if (x.PickupStopSequence > adjustSequence) x.PickupStopSequence++;
      if (x.DeliveryStopSequence > adjustSequence) x.DeliveryStopSequence++;
    });

    // Add Stop
    stops.splice(stopType === QuoteStopType.Delivery ? index + 1 : index, 0, {
      Sequence: adjustSequence + 1,
      StopType: stopType,
      StopVerb: QuoteStopVerb.Protect,
      CustomerID: 0,
      CustomerNumber: '',
      Location: '',
      DateTime: Moment().add(5, "hours").format("MM/DD/YYYY HH:mm"),
    });

    // Add New Freight
    const sequence = index + 1;
    freight.push({
      PickupStopSequence: sequence,
      DeliveryStopSequence: sequence + 1,
      Weight: 0,
      WeightUnitOfMeasure: 0,
      Pieces: 0,
      Length: 0,
      Width: 0,
      Height: 0,
      DimsUnitOfMeasure: 0,
      Stackable: false,
      StackableLimit: 0,
      Rotatable: false,
    });

    // Order freight
    freight = freight.sort((a, b) => a.PickupStopSequence - b.PickupStopSequence || a.DeliveryStopSequence - b.DeliveryStopSequence);

    // Commit Changes
    this.setState(prevState => ({
      Stops: stops,
      Locations: locations,
      Freight: freight,
      TransitData: null,
      QuoteID: 0,
      PreviousQuoteID: (prevState.QuoteID > 0) ? prevState.QuoteID : prevState.PreviousQuoteID,
      PendingChangeTypes: [...prevState.PendingChangeTypes, "ADD_STOP"]
    }), () => {
      this.updatePanelsDebounced();
    });
  }

  private removeStop(index: number) {
    // Prevent removing the last pickup or delivery
    const removeStop = this.state.Stops[index];
    const removeSequences = [removeStop.Sequence];
    if (this.state.Stops.filter(x => x.StopType === removeStop.StopType).length === 1) {
      alert("You must have at least one pickup and one delivery.");
      return;
    }

    // Remove associated freight
    let freight = this.state.Freight;
    let stops = this.state.Stops;
    let locations = this.state.Locations;

    var keepFreight = freight.filter(x => x.PickupStopSequence !== removeStop.Sequence && x.DeliveryStopSequence !== removeStop.Sequence);

    // Check for any orphaned stops by looking at freight
    freight.forEach(x => {
      if (x.PickupStopSequence === removeStop.Sequence) {
        if (keepFreight.filter(y => y.DeliveryStopSequence == x.DeliveryStopSequence).length === 0) {
          removeSequences.push(x.DeliveryStopSequence);
        }
      }
      if (x.DeliveryStopSequence === removeStop.Sequence) {
        if (keepFreight.filter(y => y.PickupStopSequence == x.PickupStopSequence).length === 0) {
          removeSequences.push(x.PickupStopSequence);
        }
      }
    });

    // Confirm removal of orphaned stops
    if (removeSequences.length > 1) {
      if (!window.confirm(`Removing this stop will also remove ${removeSequences.length - 1} associated stop(s).  Are you sure you want to continue?`)) {
        return;
      }
    }

    // Remove any orphaned stops, largest first, before removing the stop
    stops = stops.filter(x => !removeSequences.includes(x.Sequence));
    locations = locations.filter(x => !removeSequences.includes(x.Sequence));

    // Adjust all stop sequences
    var adjustArray = new Map<number, number>();
    stops.forEach(x => {
      adjustArray.set(x.Sequence, x.Sequence - removeSequences.filter(y => y < x.Sequence).length);
      x.Sequence = x.Sequence - removeSequences.filter(y => y < x.Sequence).length;
    });
    locations.forEach(x => {
      x.Sequence = adjustArray.get(x.Sequence);
    });

    // Adjust all freight sequences
    keepFreight.forEach(x => {
      x.PickupStopSequence = adjustArray.get(x.PickupStopSequence);
      x.DeliveryStopSequence = adjustArray.get(x.DeliveryStopSequence);
    });

    // Commit Changes
    this.setState(prevState => ({
      Stops: stops,
      Locations: locations,
      Freight: keepFreight,
      TransitData: null,
      QuoteID: 0,
      PreviousQuoteID: (prevState.QuoteID > 0) ? prevState.QuoteID : prevState.PreviousQuoteID,
      PendingChangeTypes: [...prevState.PendingChangeTypes, "REMOVE_STOP", "REMOVE_FREIGHT"]
    }), () => {
      this.updatePanelsDebounced();
    });
  }

  // ThruAll = 1
  // DestinationFixed = 2
  private optimizeStops(optimizationType: number) {
    if (this.state.Locations.length !== this.state.Stops.length) {
      alert("Please ensure all stops are valid before optimizing.");
      return;
    }
    const locations = this.state.Locations.map((x, index) => { return { Location: x.Location, Index: index } });
    fetchApi("/api/Quote/OptimizeRoute", { Locations: locations, RouteOptimization: optimizationType }, 'POST')
      .then((response: { Indexes: number[] }) => {
        if (isSorted(response.Indexes) == false) {
          // Reorder stops and fix sequence
          var orderedLocations = response.Indexes.map((index) => this.state.Locations[index]);
          var orderedStops = response.Indexes.map((index) => this.state.Stops[index])
            .map((x, index) => { return { ...x, Sequence: index + 1 } });
          // Fixup freight PickupStopSequence and DeliveryStopSequence
          var freight = this.state.Freight.map((x) => {
            return {
              ...x,
              PickupStopSequence: response.Indexes.indexOf(x.PickupStopSequence - 1) + 1,
              DeliveryStopSequence: response.Indexes.indexOf(x.DeliveryStopSequence - 1) + 1
            }
          }).sort((a, b) => a.PickupStopSequence - b.PickupStopSequence || a.DeliveryStopSequence - b.DeliveryStopSequence);
          this.setState({
            Locations: orderedLocations,
            Stops: orderedStops,
            Freight: freight,
            TransitData: null,
            QuoteID: 0,
            PreviousQuoteID: (this.state.QuoteID > 0) ? this.state.QuoteID : this.state.PreviousQuoteID,
            PendingChangeTypes: [...this.state.PendingChangeTypes, "OPTIMIZE_STOPS"]
          }, () => {
            this.updatePanelsDebounced();
          });
          this.showSnackBarMessage("Optimized! Stops have been rearranged.");
        } else {
          this.showSnackBarMessage("Unable to find a better route path.");
        }
      })
      .catch((error) => {
        alert('Error optimizing stops');
      });
  }

  private updateStop(data: EnterStopViewModel, changeType: string, index: number) {
    const stops = this.state.Stops;
    const locations = this.state.Locations;
    stops[index] = data;
    // These are normally updated by Validate
    if (changeType === "STOP_TYPE" && locations[index]) {
      locations[index].StopType = data.StopType;
    }
    if (changeType === "STOP_VERB" && locations[index]) {
      locations[index].StopVerb = data.StopVerb;
    }
    if ((changeType === "FIRST_STOP_DATETIME" || changeType === "STOP_DATETIME") && locations[index]) {
      locations[index].DateTime = data.DateTime ? Moment(data.DateTime).format("YYYY-MM-DD[T]HH:mm:ss") : undefined;
    }
    if (changeType === "STOP_LATEST_DATETIME" && locations[index]) {
      locations[index].LatestDateTime = data.LatestDateTime ? Moment(data.LatestDateTime).format("YYYY-MM-DD[T]HH:mm:ss") : undefined;
    }
    this.setState(prevState => ({
      Stops: stops,
      Locations: locations,
      QuoteID: 0,
      PreviousQuoteID: (prevState.QuoteID > 0) ? prevState.QuoteID : prevState.PreviousQuoteID,
      PendingChangeTypes: [...prevState.PendingChangeTypes, changeType]
    }), () => {
      this.updatePanelsDebounced();
    });
  }

  private addFreight(index: number, copy: boolean) {
    let freight = this.state.Freight;
    freight.push({
      PickupStopSequence: freight[index].PickupStopSequence,
      DeliveryStopSequence: freight[index].DeliveryStopSequence,
      Weight: copy ? freight[index].Weight : 0,
      WeightUnitOfMeasure: copy ? freight[index].WeightUnitOfMeasure : 0,
      Pieces: copy ? freight[index].Pieces : 0,
      Length: copy ? freight[index].Length : 0,
      Width: copy ? freight[index].Width : 0,
      Height: copy ? freight[index].Height : 0,
      DimsUnitOfMeasure: copy ? freight[index].DimsUnitOfMeasure : 0,
      Stackable: copy ? freight[index].Stackable : false,
      StackableLimit: copy ? freight[index].StackableLimit : 0,
      Rotatable: copy ? freight[index].Rotatable : false,
    });
    freight = freight.sort((a, b) => a.PickupStopSequence - b.PickupStopSequence || a.DeliveryStopSequence - b.DeliveryStopSequence);
    this.setState(prevState => ({
      Freight: freight,
      QuoteID: 0,
      PreviousQuoteID: (prevState.QuoteID > 0) ? prevState.QuoteID : prevState.PreviousQuoteID,
      PendingChangeTypes: [...prevState.PendingChangeTypes, copy ? "FREIGHT_PIECES" : "ADD_FREIGHT"]
    }), () => this.updatePanelsDebounced());
  }

  private copyFreight(index: number, above: boolean) {
    if (index === 0 && above) {
      alert("Cannot copy above the first freight item.");
      return;
    } else if (index === this.state.Freight.length - 1 && !above) {
      alert("Cannot copy below the last freight item.");
      return;
    }
    let freight = this.state.Freight;
    const offset = above ? index - 1 : index + 1;
    freight[index].Weight = freight[offset].Weight;
    freight[index].WeightUnitOfMeasure = freight[offset].WeightUnitOfMeasure;
    freight[index].Pieces = freight[offset].Pieces;
    freight[index].Length = freight[offset].Length;
    freight[index].Width = freight[offset].Width;
    freight[index].Height = freight[offset].Height;
    freight[index].DimsUnitOfMeasure = freight[offset].DimsUnitOfMeasure;
    freight[index].Stackable = freight[offset].Stackable;
    freight[index].StackableLimit = freight[offset].StackableLimit;
    freight[index].Rotatable = freight[offset].Rotatable;

    this.setState(prevState => ({
      Freight: freight,
      QuoteID: 0,
      PreviousQuoteID: (prevState.QuoteID > 0) ? prevState.QuoteID : prevState.PreviousQuoteID,
      PendingChangeTypes: [...prevState.PendingChangeTypes, "FREIGHT_PIECES"]
    }), () => this.updatePanelsDebounced());
  }

  private removeFreight(index: number) {
    // Prevent removing the last freight item matching the pickup/delivery stop sequence
    const removeFreight = this.state.Freight[index];
    const freightCount = this.state.Freight.filter(x => x.PickupStopSequence === removeFreight.PickupStopSequence && x.DeliveryStopSequence === removeFreight.DeliveryStopSequence).length;
    if (freightCount === 1) {
      alert("You must have at least one freight item for each pickup/delivery stop sequence.");
      return;
    }

    // Remove the freight item
    let freight = this.state.Freight;
    freight.splice(index, 1);
    this.setState(prevState => ({
      Freight: freight,
      QuoteID: 0,
      PreviousQuoteID: (prevState.QuoteID > 0) ? prevState.QuoteID : prevState.PreviousQuoteID,
      PendingChangeTypes: [...prevState.PendingChangeTypes, "REMOVE_FREIGHT"]
    }), () => this.updatePanelsDebounced());
  }

  private updateCharge(index: number, data: { ChargeType: ChargeType, Amount: number }): void {
    if (this.state.QuoteData) {

      const LineItems = this.state.QuoteData.LineItems;
      LineItems[index].Rate = data.Amount;
      LineItems[index].Amount = data.Amount;

      const QuoteData: QuoteValue = {
        RateComment: 'User Override',
        Amount: LineItems.reduce((a, b) => +a + +b.Amount, 0),
        LineHaulAmount: LineItems.filter(x => x.RateType == 70 || x.RateType == 76).reduce((a, b) => +a + +b.Amount, 0),
        FuelAmount: LineItems.filter(x => x.RateType == 85).reduce((a, b) => +a + +b.Amount, 0),
        AccessorialAmount: LineItems.filter(x => x.RateType == 65 && accessorialsNoDriverPay.indexOf(x.RateDescriptionID) == -1 && accessorialsFullDriverPay.indexOf(x.RateDescriptionID) == -1).reduce((a, b) => +a + +b.Amount, 0),
        BonusAmount: LineItems.filter(x => x.RateType == 65 && accessorialsFullDriverPay.indexOf(x.RateDescriptionID) != -1).reduce((a, b) => +a + +b.Amount, 0),
        LineItems
      };

      this.setState(prevState => ({
        QuoteID: 0,
        SmartbidDataUsed: false,
        QuoteData,
        WinPercentageData: null,
        PreviousQuoteID: (prevState.QuoteID > 0) ? prevState.QuoteID : prevState.PreviousQuoteID,
        PendingChangeTypes: prevState.PendingChangeTypes
      }), () => {
        this.updatePanelsDebounced();
      })
    }
  }

  private addCharge(data: { ChargeType: ChargeType, Quantity: number, QuantityUoM: number, Rate: number, RateUoM: number, Amount: number }): void {
    if (this.state.QuoteData) {
      this.setState(prevState => ({
        QuoteID: 0,
        SmartbidDataUsed: false,
        QuoteData: this.calculateCharges(prevState.QuoteData ? [...prevState.QuoteData.LineItems, {
          RateDescriptionID: data.ChargeType.RateDescriptionID,
          Name: data.ChargeType.Description,
          RateType: data.ChargeType.RateType,
          Quantity: data.Quantity,
          QuantityUoM: data.QuantityUoM,
          Rate: data.Rate,
          RateUoM: data.RateUoM,
          Amount: data.Amount,
          IsAutoRated: false,
        }] : []),
        WinPercentageData: null,
        PreviousQuoteID: (prevState.QuoteID > 0) ? prevState.QuoteID : prevState.PreviousQuoteID
      }), () => {
        this.updatePanelsDebounced();
      })
    }
  }

  private replaceCharges(
    data: Array<{ ChargeType: ChargeType, Quantity: number, QuantityUoM: number, Rate: number, RateUoM: number, Amount: number }>,
    smartbidUsed: boolean,
    smartbidRateUpDown: number,
    smartbidWinPercentagePrevious: number,
    smartbidWinPercentageCurrent: number,
  ) {
    this.setState(prevState => ({
      QuoteID: 0,
      SmartbidDataUsed: smartbidUsed,
      SmartbidRateUpDown: prevState.SmartbidRateUpDown === null ? smartbidRateUpDown : prevState.SmartbidRateUpDown + smartbidRateUpDown,
      SmartbidWinPercentagePrevious: prevState.SmartbidWinPercentagePrevious === null ? smartbidWinPercentagePrevious : prevState.SmartbidWinPercentagePrevious,
      SmartbidWinPercentageCurrent: smartbidWinPercentageCurrent,
      QuoteData: this.calculateCharges(data.map((lineItem) => {
        return {
          RateDescriptionID: lineItem.ChargeType.RateDescriptionID,
          Name: lineItem.ChargeType.Description,
          RateType: lineItem.ChargeType.RateType,
          Quantity: lineItem.Quantity,
          QuantityUoM: lineItem.QuantityUoM,
          Rate: lineItem.Rate,
          RateUoM: lineItem.RateUoM,
          Amount: lineItem.Amount,
          IsAutoRated: true,
        }
      })),
      WinPercentageData: null,
      PreviousQuoteID: (prevState.QuoteID > 0) ? prevState.QuoteID : prevState.PreviousQuoteID,
    }), () => {
      this.updatePanelsDebounced();
      this.fetchWinPercentage(this.state.ValidationRequestHashRate);
    })
  }

  private calculateCharges(LineItems: QuoteLineItemValue[]): QuoteValue {
    return {
      RateComment: 'User Override',
      Amount: LineItems.reduce((a, b) => +a + +b.Amount, 0),
      LineHaulAmount: LineItems.filter(x => x.RateType == 70 || x.RateType == 76).reduce((a, b) => +a + +b.Amount, 0),
      FuelAmount: LineItems.filter(x => x.RateType == 85).reduce((a, b) => +a + +b.Amount, 0),
      AccessorialAmount: LineItems.filter(x => x.RateType == 65 && accessorialsNoDriverPay.indexOf(x.RateDescriptionID) == -1 && accessorialsFullDriverPay.indexOf(x.RateDescriptionID) == -1).reduce((a, b) => +a + +b.Amount, 0),
      BonusAmount: LineItems.filter(x => x.RateType == 65 && accessorialsFullDriverPay.indexOf(x.RateDescriptionID) != -1).reduce((a, b) => +a + +b.Amount, 0),
      LineItems
    }
  }

  private removeCharge(index: number): void {
    if (this.state.QuoteData && window.confirm("Are you sure you want to remove this charge?")) {
      const LineItems = this.state.QuoteData.LineItems.filter((d, i) => i !== index);
      const QuoteData = {
        RateComment: 'User Override',
        Amount: LineItems.reduce((a, b) => +a + +b.Amount, 0),
        LineHaulAmount: LineItems.filter(x => x.RateType == 70 || x.RateType == 76).reduce((a, b) => +a + +b.Amount, 0),
        FuelAmount: LineItems.filter(x => x.RateType == 85).reduce((a, b) => +a + +b.Amount, 0),
        AccessorialAmount: LineItems.filter(x => x.RateType == 65 && accessorialsNoDriverPay.indexOf(x.RateDescriptionID) == -1 && accessorialsFullDriverPay.indexOf(x.RateDescriptionID) == -1).reduce((a, b) => +a + +b.Amount, 0),
        BonusAmount: LineItems.filter(x => x.RateType == 65 && accessorialsFullDriverPay.indexOf(x.RateDescriptionID) != -1).reduce((a, b) => +a + +b.Amount, 0),
        LineItems
      }
      this.setState(prevState => ({
        QuoteID: 0,
        SmartbidDataUsed: false,
        QuoteData,
        PreviousQuoteID: (prevState.QuoteID > 0) ? prevState.QuoteID : prevState.PreviousQuoteID
      }), () => {
        this.updatePanelsDebounced();
      });
    }
  }

  private updateFreight(data: EnterFreightViewModel, changeType: string, index: number) {
    const freight = this.state.Freight;
    freight[index] = data;
    this.setState(prevState => ({
      Freight: freight,
      QuoteID: 0,
      PreviousQuoteID: (prevState.QuoteID > 0) ? prevState.QuoteID : prevState.PreviousQuoteID,
      PendingChangeTypes: [...prevState.PendingChangeTypes, changeType]
    }), () => {
      this.updatePanelsDebounced();
    });
  }

  private viewFit(vehicleId: number) {
    var dims = JSON.stringify(this.state.Freight.map(x => {
      return {
        Pieces: x.Pieces,
        Length: x.Length,
        Width: x.Width,
        Height: x.Height,
        Rotatable: x.Rotatable,
        Stackable: x.Stackable,
        StackableLimit: x.StackableLimit,
      }
    }));
    openWindow('/Quote/Calculate3DFit/' + vehicleId + '?Dims=' + encodeURI(dims), 900);
  }

  private updatePanelsDebounced = debounce(this.updatePanels, 400);
  private updateOffersDebounced = debounce(this.getOffers, 5000);

  private updatePanels() {
    const data = {
      Stops: this.state.Stops,
      Freight: this.state.Freight,
      VehicleTypeID: this.state.VehicleTypeID,
      BillingCustomerNumber: this.state.BillingCustomerNumber,
      AuthorizationCustomerNumber: this.state.AuthorizationCustomerNumber,
    }

    // What needs updating?
    const updatePanels = this.requireUpdate(this.state.PendingChangeTypes);
    if (updatePanels.length === 0) return;

    const validationHash = Math.random().toString(36).substring(7);
    const validationHashTransit = updatePanels.find(x => x === "TRANSIT") ? validationHash : this.state.ValidationRequestHashTransit;
    const validationHashRate = updatePanels.find(x => x === "RATE") ? validationHash : this.state.ValidationRequestHashRate;
    const validationHashFuelRate = updatePanels.find(x => x === "FUELRATE") ? validationHash : this.state.ValidationRequestHashFuelRate;
    const validationHashVehicles = updatePanels.find(x => x === "VEHICLES") ? validationHash : this.state.ValidationRequestHashVehicles; //store the latest
    const validationHashExternalRates = updatePanels.find(x => x === "EXTERNALRATES") ? validationHash : this.state.ValidationRequestHashExternalRates;
    const validationHashAvgWaitTimes = updatePanels.find(x => x === "AVGWAITTIMES") ? validationHash : this.state.ValidationRequestHashAvgWaitTimes;


    //EXTERNALRATES

    this.setState({
      ValidationRequestHash: validationHash,
      ValidationRequestHashTransit: validationHashTransit,
      ValidationRequestHashRate: validationHashRate,
      ValidationRequestHashFuelRate: validationHashFuelRate,
      ValidationRequestHashVehicles: validationHashVehicles,
      ValidationRequestHashExternalRates: validationHashExternalRates,
      ValidationRequestHashAvgWaitTimes: validationHashAvgWaitTimes,
      PendingChangeTypes: []
    });

    fetchApi("/api/Quote/Validate", data, 'POST')
      .then((response: { Stops: Array<{ Sequence: number, CustomerID: number, CustomerNumber: string, Location: any }>, Links: ILink[], BillingCustomerID?: number, AuthorizationCustomerID?: number, PCMilerVersion: number | null }) => {
        if (this.state.ValidationRequestHash !== validationHash) {
          return;
        }
        response.BillingCustomerID = response.BillingCustomerID || 0;
        response.AuthorizationCustomerID = response.AuthorizationCustomerID || 0;
        if (response.AuthorizationCustomerID > 0 && this.state.AuthorizationCustomerID !== response.AuthorizationCustomerID) {
          this.populateAuthorizationCustomerContacts(response.AuthorizationCustomerID);
        }
        this.setState((prevState) => ({
          Links: response.Links,
          Stops: prevState.Stops.length == response.Stops.length ? response.Stops.map((x, index) => {
            return x.CustomerID > 0 ?
              {
                ...prevState.Stops[index],
                ...{ CustomerID: x.CustomerID, CustomerNumber: x.CustomerNumber, Location: x.Location.Zip + ' ' + x.Location.City + ', ' + x.Location.State }
              }
              : prevState.Stops[index];
          }) : prevState.Stops,
          Locations: response.Stops.map((x, index) => {
            return {
              Sequence: x.Sequence,
              QuoteStopID: this.state.Stops[index].QuoteStopID,
              StopType: this.state.Stops[index].StopType,
              StopVerb: this.state.Stops[index].StopVerb,
              CustomerID: x.CustomerID,
              CustomerNumber: x.CustomerNumber,
              Location: x.Location,
              DateTime: this.state.Stops[index].DateTime ? Moment(this.state.Stops[index].DateTime).format("YYYY-MM-DD[T]HH:mm:ss") : undefined,
              LatestDateTime: this.state.Stops[index].StopVerb === QuoteStopVerb.Window && this.state.Stops[index].LatestDateTime ? Moment(this.state.Stops[index].LatestDateTime).format("YYYY-MM-DD[T]HH:mm:ss") : undefined,
            }
          }),
          BillingCustomerID: response.BillingCustomerID,
          PCMilerVersion: response.PCMilerVersion,
          AuthorizationCustomerID: response.AuthorizationCustomerID,
          AuthorizationCustomerContacts: response.AuthorizationCustomerID === 0 ? [] : prevState.AuthorizationCustomerContacts,
        }), () => {
          if (this.state.Links.find((x) => x.Name === 'Rate') && this.state.QuoteID == 0 && updatePanels.find(x => x === "RATE") && !updatePanels.find(x => x === "TRANSIT") && this.state.TransitData) {
            if (this.state.QuoteData?.RateComment === 'User Override') {
              this.setState({ QuoteDataOutdated: true });
            } else {
              this.setState({ QuoteDataIsLoading: true });
              const data = {
                Stops: this.state.Locations.map((x) => { return { StopType: x.StopType, DateTime: x.DateTime, CustomerID: x.CustomerID, CustomerNumber: x.CustomerNumber, City: x.Location.City, State: x.Location.State, Zip: x.Location.Zip } }),
                Distance: this.state.TransitData.Distance,
                Weight: this.state.Freight.reduce((a, b) => +a + +b.Weight, 0),
                VehicleTypeID: this.state.VehicleTypeID,
                BillingCustomerID: this.state.BillingCustomerID || this.state.AuthorizationCustomerID,
                BillingCustomerNumber: this.state.BillingCustomerNumber || this.state.AuthorizationCustomerNumber,
                HazMat: this.state.HazMat,
                LiftGate: this.state.LiftGate
              };
              const link = this.state.Links.find(x => x.Name === 'Rate');
              fetchApi(link.Link, data, link.Method)
                .then((data: QuoteValue) => {
                  if (this.state.ValidationRequestHashRate === validationHashRate) {
                    data.Amount = data.LineItems.reduce((a, b) => +a + +b.Amount, 0);
                    data.LineHaulAmount = data.LineItems.filter(x => x.RateType == 70 || x.RateType == 76).reduce((a, b) => +a + +b.Amount, 0);
                    data.FuelAmount = data.LineItems.filter(x => x.RateType == 85).reduce((a, b) => +a + +b.Amount, 0);
                    data.AccessorialAmount = data.LineItems.filter(x => x.RateType == 65 && accessorialsNoDriverPay.indexOf(x.RateDescriptionID) == -1 && accessorialsFullDriverPay.indexOf(x.RateDescriptionID) == -1).reduce((a, b) => +a + +b.Amount, 0);
                    data.BonusAmount = data.LineItems.filter(x => x.RateType == 65 && accessorialsFullDriverPay.indexOf(x.RateDescriptionID) != -1).reduce((a, b) => +a + +b.Amount, 0);
                    var updatedFuelRate = data.LineItems.find(x => x.RateDescriptionID == 5001);
                    if (updatedFuelRate) this.setState({ FuelRate: updatedFuelRate.Rate });
                    this.setState({ QuoteData: data, WinPercentageData: null, SmartbidDataUsed: false, QuoteDataIsLoading: false, QuoteDataOutdated: false }, () => {
                      if (this.state.Links.find((x) => x.Name === 'SmartbidRate')) {
                        this.fetchWinPercentage(this.state.ValidationRequestHashRate);
                      } else if (this.state.SmartbidData !== null || this.state.SmartbidDataIsLoading) {
                        this.setState({ SmartbidData: null, SmartbidDataIsLoading: false });
                      }
                    });
                  }
                })
                .catch(() => {
                  if (this.state.ValidationRequestHashRate === validationHashRate) {
                    this.setState({ QuoteData: null, WinPercentageData: null, SmartbidDataUsed: false, QuoteDataIsLoading: false });
                  }
                });
            }
          }

          if (this.state.Links.find((x) => x.Name === 'AvgWaitTimes') && (updatePanels.find(x => x === "AVGWAITTIMES") || this.state.AvgWaitTimeData === null)) {
            this.setState({ AvgWaitTimeDataIsLoading: true });
            const data = {
              Stops: this.state.Locations.map((x) => { return { StopType: x.StopType, CustomerID: x.CustomerID } }),
              VehicleTypeID: this.state.VehicleTypeID,
            };
            const link = this.state.Links.find(x => x.Name === 'AvgWaitTimes');
            fetchApi(link.Link, data, link.Method)
              .then((data) => {
                if (this.state.ValidationRequestHashAvgWaitTimes === validationHashAvgWaitTimes) {
                  this.setState({ AvgWaitTimeData: data.AvgWaitTimeData, AvgWaitTimeDataIsLoading: false });
                }
              });
          }

          if (this.state.Stops.length == response.Stops.length && this.state.Links.find((x) => x.Name === 'Transit') && (updatePanels.find(x => x === "TRANSIT") || this.state.TransitData === null)) {
            this.setState({ TransitDataIsLoading: true });
            const data = {
              Locations: this.state.Locations.map(({ Location, DateTime }) => ({ Location, DateTime })),
              DeadheadMiles: this.state.DeadheadMiles,
              RemainingHoursofService: Moment(this.state.RemainingHoursofService).format("HH:mm"),
              VehicleTypeID: this.state.VehicleTypeID,
              Weight: this.state.Freight.reduce((a, b) => +a + +b.Weight, 0).toString(),
              PCMilerVersion: this.state.PCMilerVersion,
            }
            const link = this.state.Links.find(x => x.Name === 'Transit');
            fetchApi(link.Link, data, link.Method)
              .then((data: TransitValue) => {
                if (this.state.ValidationRequestHashTransit === validationHashTransit) {
                  this.setState({ TransitData: data, TransitDataIsLoading: false }, () => {
                    this.populateHOSAlertsForLoadOneTrucks();

                    if (this.state.Links.find((x) => x.Name === 'Rate') && this.state.QuoteID == 0 && (updatePanels.find(x => x === "RATE") || this.state.QuoteData === null)) {
                      if (this.state.QuoteData?.RateComment === 'User Override') {
                        this.setState({ QuoteDataOutdated: true });
                      } else {
                        this.setState({ QuoteDataIsLoading: true });
                        const data = {
                          Stops: this.state.Locations.map((x) => { return { StopType: x.StopType, DateTime: x.DateTime, City: x.Location.City, State: x.Location.State, Zip: x.Location.Zip } }),
                          Distance: this.state.TransitData.Distance,
                          Weight: this.state.Freight.reduce((a, b) => +a + +b.Weight, 0),
                          VehicleTypeID: this.state.VehicleTypeID,
                          BillingCustomerID: this.state.BillingCustomerID || this.state.AuthorizationCustomerID,
                          BillingCustomerNumber: this.state.BillingCustomerNumber || this.state.AuthorizationCustomerNumber,
                          HazMat: this.state.HazMat,
                          LiftGate: this.state.LiftGate,
                        };
                        const link = this.state.Links.find(x => x.Name === 'Rate');
                        fetchApi(link.Link, data, link.Method)
                          .then((data: QuoteValue) => {
                            if (this.state.ValidationRequestHashRate === validationHashRate) {
                              data.Amount = data.LineItems.reduce((a, b) => +a + +b.Amount, 0);
                              data.LineHaulAmount = data.LineItems.filter(x => x.RateType == 70 || x.RateType == 76).reduce((a, b) => +a + +b.Amount, 0);
                              data.FuelAmount = data.LineItems.filter(x => x.RateType == 85).reduce((a, b) => +a + +b.Amount, 0);
                              data.AccessorialAmount = data.LineItems.filter(x => x.RateType == 65 && accessorialsNoDriverPay.indexOf(x.RateDescriptionID) == -1 && accessorialsFullDriverPay.indexOf(x.RateDescriptionID) == -1).reduce((a, b) => +a + +b.Amount, 0);
                              data.BonusAmount = data.LineItems.filter(x => x.RateType == 65 && accessorialsFullDriverPay.indexOf(x.RateDescriptionID) != -1).reduce((a, b) => +a + +b.Amount, 0);
                              var updatedFuelRate = data.LineItems.find(x => x.RateDescriptionID == 5001);
                              if (updatedFuelRate) this.setState({ FuelRate: updatedFuelRate.Rate });
                              this.setState({ QuoteData: data, WinPercentageData: null, SmartbidDataUsed: false, QuoteDataIsLoading: false, QuoteDataOutdated: false }, () => {
                                if (this.state.Links.find((x) => x.Name === 'SmartbidRate')) {
                                  this.fetchWinPercentage(this.state.ValidationRequestHashRate);
                                } else if (this.state.SmartbidData !== null || this.state.SmartbidDataIsLoading) {
                                  this.setState({ SmartbidData: null, SmartbidDataIsLoading: false });
                                }
                              });
                            }
                          })
                          .catch(() => {
                            if (this.state.ValidationRequestHashRate === validationHashRate) {
                              this.setState({ QuoteData: null, WinPercentageData: null, SmartbidDataUsed: false, QuoteDataIsLoading: false });
                            }
                          });
                      }
                    }
                  });
                }
              })
              .catch(() => {
                if (this.state.ValidationRequestHashTransit === validationHashTransit) {
                  this.setState({ TransitData: null, TransitDataIsLoading: false }, () => { this.populateHOSAlertsForLoadOneTrucks(); });
                }
              });
          }

          let veh = false;
          if (this.state.Links.find((x) => x.Name === 'Vehicles') && (updatePanels.find(x => x === "VEHICLES"))) {
            veh = true;
          }
          let externalRates = false;
          if (updatePanels.find(x => x === "EXTERNALRATES")) {
            externalRates = true;
          }
          if (veh || externalRates) {
            if (veh && externalRates) {
              this.setState({ VehicleDataIsLoading: false, VehicleData: null, AllianceVehicleData: null, CarrierData: null, FedExRateData: null, DHLRateData: null, EShippingRateData: null, UPSRateData: null }, () => {
                this.vehiclesPanel.current.forceRefresh();
              });
            } else if (veh) {
              this.setState({ VehicleDataIsLoading: false, VehicleData: null, AllianceVehicleData: null, CarrierData: null }, () => {
                this.vehiclesPanel.current.forceRefresh();
              });
            } else {
              this.setState({ VehicleDataIsLoading: false, FedExRateData: null, DHLRateData: null, EShippingRateData: null, UPSRateData: null }, () => {
                this.vehiclesPanel.current.forceRefresh();
              });
            }
          }

          if ((updatePanels.find(x => x === "FUELRATE") || this.state.FuelRate === 0) && (this.state.BillingCustomerID || this.state.AuthorizationCustomerID) && this.state.Locations[0] && this.state.Locations[0].DateTime) {
            const data = {
              CustomerID: this.state.BillingCustomerID || this.state.AuthorizationCustomerID,
              Date: this.state.Locations[0].DateTime,
              VehicleTypeID: this.state.VehicleTypeID
            }
            fetchApi('/api/Quote/GetFuelRate', data, 'POST')
              .then((FuelRate: number) => {
                if (this.state.ValidationRequestHashFuelRate === validationHashFuelRate) {
                  this.setState({ FuelRate });
                }
              });
          }
        })
      })
      .catch((err) => console.error(err));
  }

  private populateAuthorizationCustomerContacts(customerID: number) {
    fetchApi('/api/Quote/CustomerContacts', { CustomerID: customerID }, 'POST')
      .then((response: { PerferredContactID: number, Contacts: IDName[] }) => {
        this.setState(prevState => ({ AuthorizationCustomerContacts: response.Contacts, AuthorizationCustomerContactID: prevState.AuthorizationCustomerContactID || response.PerferredContactID || 0 }));
      });
  }

  private populateHOSAlertsForLoadOneTrucks() {
    if (this.state.QuoteID === 0 && !this.state.VehicleDataIsLoading && !this.state.TransitDataIsLoading &&
      this.state.VehicleData != null && this.state.TransitData != null &&
      this.state.VehicleData.length > 0 && this.state.TransitData.Distance > 0) {

      const pickup = this.state.Locations[0];
      const delivery = this.state.Locations[this.state.Locations.length - 1];

      const data = {
        Vehicles: this.state.VehicleData,
        StartDateTime: pickup.DateTime,
        EndDateTime: delivery.StopVerb === QuoteStopVerb.Window ? delivery.LatestDateTime : delivery.DateTime,
        StopCount: this.state.Locations.length,
        BorderCrossingCount: this.IsInternational() ? 1 : 0,
        LoadedMiles: this.state.TransitData ? this.state.TransitData.Distance : 0
      }
      fetchApi('/api/Quote/GetVehicleTransitTime', data, 'POST')
        .then((data: { Vehicles: VehicleTransitTimeValue[] }) => {
          const vehicleDataState: VehicleValue[] = JSON.parse(JSON.stringify(this.state.VehicleData));
          if (vehicleDataState != null) {
            data.Vehicles.forEach((item) => {
              const idx = vehicleDataState.findIndex(x => x.VehicleID === item.VehicleId);
              if (idx != -1) {
                vehicleDataState[idx].HasHoursRemaining = item.HasHoursRemaining;
                vehicleDataState[idx].InServiceBackOn = item.InServiceBackOn;
                vehicleDataState[idx].TransitTimeTooltip = item.TransitTimeTooltip;
                vehicleDataState[idx].Hash = item.Hash;
              }
            });
            this.setState({ VehicleData: vehicleDataState });
          }
        });
    }
  }

  private requireUpdate(pendingChangeTypes: string[]): string[] {
    var updatePanels = new Set<string>();

    pendingChangeTypes.forEach((changeType) => {
      switch (changeType) {
        case "STOP_TYPE":
          updatePanels.add("AVGWAITTIMES");
          break;
        case "STOP_VERB":
        case "ADD_STOP":
        case "ADD_FREIGHT":
        case "THREAD":
        case "ROUTENAME":
        case "NOTE":
        case "OVERRIDEDISTANCE":
        case "FREIGHT_DIMS_TYPE":
        case "CUSTOMERCONTACT":
        case "BROKER":
        case "BOOKINGSOURCE":
          break;
        case "AUTHORIZATION_CUSTOMER":
          updatePanels.add("VALIDATE");
          if (this.state.BillingCustomerID === 0) {
            updatePanels.add("RATE");
            updatePanels.add("FUELRATE");
          }
          break;
        case "STOP_DATETIME":
        case "STOP_LATEST_DATETIME":
        case "DEADHEADMILES":
        case "REMAININGHOURSOFSERVICE":
          updatePanels.add("TRANSIT");
          break;
        case "FIRST_STOP_DATETIME":
          updatePanels.add("TRANSIT");
          updatePanels.add("VEHICLES");
          updatePanels.add("FUELRATE");
          updatePanels.add("EXTERNALRATES");
          break;
        case "QUOTE":
          updatePanels.add("TRANSIT");
          updatePanels.add("RATE");
          updatePanels.add("VEHICLES");
          updatePanels.add("FUELRATE");
          updatePanels.add("EXTERNALRATES");
          updatePanels.add("AVGWAITTIMES");
          break;
        case "RATE":
          updatePanels.add("RATE");
          break;
        case "FIRST_STOP_LOCATION":
        case "STOP_LOCATION":
        case "REMOVE_STOP":
        case "OPTIMIZE_STOPS":
          updatePanels.add("TRANSIT");
          updatePanels.add("RATE");
          updatePanels.add("VEHICLES");
          updatePanels.add("EXTERNALRATES");
          updatePanels.add("AVGWAITTIMES");
          break;
        case "VEHICLETYPE":
          updatePanels.add("TRANSIT");
          updatePanels.add("RATE");
          updatePanels.add("VEHICLES");
          updatePanels.add("AVGWAITTIMES");
          break;
        case "CUSTOMER":
          updatePanels.add("RATE");
          updatePanels.add("FUELRATE");
          break;
        case "REMOVE_FREIGHT":
        case "FREIGHT_WEIGHT":
          // updatePanels.add("RATE"); (Might miss rates)
          updatePanels.add("VEHICLES");
          updatePanels.add("EXTERNALRATES");
          break;
        case "EXTERNAL_RATES":
          updatePanels.add("EXTERNALRATES");
          break;
        case "HAZMAT":
        case "LIFTGATE":
          updatePanels.add("RATE");
          updatePanels.add("EXTERNALRATES");
          updatePanels.add("VEHICLES");
          break;
        case "DOCKHIGH":
        case "FREIGHT_PIECES":
        case "FREIGHT_LENGTH":
        case "FREIGHT_WIDTH":
        case "FREIGHT_HEIGHT":
          updatePanels.add("EXTERNALRATES");
          updatePanels.add("VEHICLES");
          break;
        case "TSA":
        case "FAST":
        case "FREIGHT_STACKABLE":
        case "FREIGHT_ROTATABLE":
        case "AIRRIDE":
        case "VEHICLES":
          updatePanels.add("VEHICLES");
          break;
        default:
          throw new Error(`Unhandled update: ${changeType}`);
      }
    });

    var updatePanelsArray: string[] = [];
    updatePanels.forEach(x => updatePanelsArray.push(x))
    return updatePanelsArray;
  }

  private toggleCollapse() {
    // Can't collapse when editing
    if (this.state.EditLayout) {
      return;
    }
    this.setState(prevState => ({
      Collapse: !prevState.Collapse,
      Layout: prevState.Collapse ? prevState.PrevLayout : prevState.Layout.map(obj => ({ ...obj, static: false })),
      PrevLayout: prevState.Collapse ? prevState.PrevLayout : prevState.Layout,
    }), () => {
      if (this.state.Collapse) {
        this.setState(prevState => ({
          Layout: prevState.Layout.map(obj => ({ ...obj, static: true })),
        }));
      }
    });
  }

  private toggleTSA = () => {
    this.setState(prevState => ({
      TSA: !prevState.TSA,
      QuoteID: 0,
      PreviousQuoteID: (prevState.QuoteID > 0) ? prevState.QuoteID : prevState.PreviousQuoteID,
      PendingChangeTypes: [...prevState.PendingChangeTypes, "TSA"]
    }), () => this.updatePanelsDebounced());
  }

  private toggleFAST = () => {
    this.setState(prevState => ({
      FAST: !prevState.FAST,
      QuoteID: 0,
      PreviousQuoteID: (prevState.QuoteID > 0) ? prevState.QuoteID : prevState.PreviousQuoteID,
      PendingChangeTypes: [...prevState.PendingChangeTypes, "FAST"]
    }), () => this.updatePanelsDebounced());
  }

  private toggleHazMat = () => {
    this.setState(prevState => ({
      HazMat: !prevState.HazMat,
      QuoteID: 0,
      PreviousQuoteID: (prevState.QuoteID > 0) ? prevState.QuoteID : prevState.PreviousQuoteID,
      PendingChangeTypes: [...prevState.PendingChangeTypes, "HAZMAT"]
    }), () => this.updatePanelsDebounced());
  }

  private toggleDockHigh = () => {
    this.setState(prevState => ({
      DockHigh: !prevState.DockHigh,
      VehicleTypeID: (!prevState.DockHigh && prevState.VehicleTypeID < 3) ? 3 : prevState.VehicleTypeID,
      QuoteID: 0,
      PreviousQuoteID: (prevState.QuoteID > 0) ? prevState.QuoteID : prevState.PreviousQuoteID,
      PendingChangeTypes: [
        ...prevState.PendingChangeTypes,
        ...(!prevState.DockHigh && prevState.VehicleTypeID < 3) ? ["DOCKHIGH", "VEHICLETYPE"] : ["DOCKHIGH"]
      ]
    }), () => this.updatePanelsDebounced());
  }

  private toggleLiftGate = () => {
    this.setState(prevState => ({
      LiftGate: !prevState.LiftGate,
      QuoteID: 0,
      PreviousQuoteID: (prevState.QuoteID > 0) ? prevState.QuoteID : prevState.PreviousQuoteID,
      PendingChangeTypes: [...prevState.PendingChangeTypes, "LIFTGATE"]
    }), () => this.updatePanelsDebounced());
  }

  private toggleAirRide = () => {
    this.setState(prevState => ({
      AirRide: !prevState.AirRide,
      QuoteID: 0,
      PreviousQuoteID: (prevState.QuoteID > 0) ? prevState.QuoteID : prevState.PreviousQuoteID,
      PendingChangeTypes: [...prevState.PendingChangeTypes, "AIRRIDE"]
    }), () => this.updatePanelsDebounced());
  }

  private handleVehicleTypeChange(e: DropDownListChangeEvent) {
    const vehicleTypeId = e.target.value.value;
    this.setState(prevState => ({
      VehicleTypeID: vehicleTypeId,
      QuoteID: 0,
      PreviousQuoteID: (prevState.QuoteID > 0) ? prevState.QuoteID : prevState.PreviousQuoteID,
      PendingChangeTypes: [...prevState.PendingChangeTypes, "VEHICLETYPE"]
    }), () => this.updatePanelsDebounced());
  }

  private handleBookingSourceChange(e: DropDownListChangeEvent) {
    const bookingSource = e.target.value.value;
    this.setState(prevState => ({
      BookingSource: bookingSource,
      QuoteID: 0,
      PreviousQuoteID: (prevState.QuoteID > 0) ? prevState.QuoteID : prevState.PreviousQuoteID,
      PendingChangeTypes: [...prevState.PendingChangeTypes, "BOOKINGSOURCE"]
    }), () => this.updatePanelsDebounced());
  }

  private updateBillingSpecialInstructions(customerId: number) {
    fetchApi(`/api/Customer/SpecialInstructions/${customerId}`)
      .then((response: Array<{ Type: number, Display: number, Instruction: string }>) => {
        const BillingInstructions = response
          .filter(x => x.Type == 66)
          .map(function (x) { return { Display: x.Display, Instruction: x.Instruction } });
        this.setState({ BillingInstructions });
      })
  }

  private updateAuthorizationSpecialInstructions(customerId: number) {
    fetchApi(`/api/Customer/SpecialInstructions/${customerId}`)
      .then((response: Array<{ Type: number, Display: number, Instruction: string }>) => {
        const AuthorizationInstructions = response
          .filter(x => x.Type == 65)
          .map(function (x) { return { Display: x.Display, Instruction: x.Instruction } });
        this.setState({ AuthorizationInstructions });
      })
  }



  private openThread() {
    if (this.state.ThreadID.startsWith("cnv_") || this.state.ThreadID.startsWith("msg_")) {
      const win = window.open(`https://app.frontapp.com/open/${this.state.ThreadID}`, '_blank');
      win.focus();
    }
  }

  private addQuote() {

    if (this.state.BillingCustomerID === 0 && this.state.AuthorizationCustomerID === 0) {
      alert("Please enter a Billing Customer or Authorization Customer");
      return;
    }

    if (this.state.VehicleTypeID === 0) {
      alert("Please select a Vehicle Type");
      return;
    }

    if (this.state.Locations.length !== this.state.Stops.length) {
      alert("Please verify locations entered");
      return;
    }

    if (!this.state.QuoteData || this.state.QuoteData.Amount === 0) {
      alert("Unabled save quote without rate");
      return;
    }

    if (this.state.IsSaving) {
      return;
    }

    this.setState({ IsSaving: true });

    const data = {
      ThreadID: this.state.ThreadID,
      RouteName: this.state.RouteName,
      Source: 1,
      Stops: this.state.Locations,
      Freight: this.state.Freight,
      LineItems: this.state.QuoteData.LineItems,
      RateComment: this.state.QuoteData.RateComment,
      SmartbidUsed: this.state.SmartbidDataUsed,
      SmartbidRateUpDown: this.state.SmartbidRateUpDown,
      SmartbidWinPercentagePrevious: this.state.SmartbidWinPercentagePrevious,
      SmartbidWinPercentageCurrent: this.state.SmartbidWinPercentageCurrent,
      VehicleTypeID: this.state.VehicleTypeID,
      BillingCustomerID: this.state.BillingCustomerID,
      AuthorizationCustomerID: this.state.AuthorizationCustomerID,
      AuthorizationCustomerContactID: this.state.AuthorizationCustomerContactID,
      BrokerID: this.state.BrokerID,
      BookingSource: this.state.BookingSource,
      DockHigh: this.state.DockHigh,
      LiftGate: this.state.LiftGate,
      AirRide: this.state.AirRide,
      HazMat: this.state.HazMat,
      FAST: this.state.FAST,
      TSA: this.state.TSA,
      OverrideDistance: this.state.OverrideDistance,
      Total: this.state.QuoteData.Amount,
      SearchRadius: this.state.Distance,
      Note: this.state.Note,
      Force: this.state.DuplicateQuotes.length > 0
    }

    fetchApi('/api/Quote/Save', data, 'POST')
      .then((response: {
        QuoteID: number,
        QuoteStopIDs: number[],
        QuoteFreightIDs: number[],
        CreatedByUserName: string,
        DuplicateQuotes: DuplicateQuote[]
      }) => {
        if (response.DuplicateQuotes.length > 0) {
          this.setState({ IsSaving: false, DuplicateQuotes: response.DuplicateQuotes });
          return;
        }
        // Update QuoteStopID, QuoteFrieghtID
        this.state.Stops.forEach((stop, index) => {
          stop.QuoteStopID = response.QuoteStopIDs[index];
        });
        this.state.Freight.forEach((freight, index) => {
          freight.QuoteFreightID = response.QuoteFreightIDs[index];
        });
        this.setState(prevState => ({
          QuoteID: response.QuoteID,
          BookingSource: prevState.BookingSource == 0 ? 1 : prevState.BookingSource, // Default to Web
          CreatedByUserName: response.CreatedByUserName,
          IsTemplate: false,
          Unassigned: false,
          OrderID: null,
          OrderNumber: null,
          OrderStatus: null,
          OrderModNumDiff: null,
          PreviousQuoteID: 0,
          OfferData: prevState.PreviousQuoteID > 0 ? null : prevState.OfferData,
          QuoteDataOutdated: false,
          DuplicateQuotes: [], // Hide duplicate quotes window
          PendingChangeTypes: prevState.PreviousQuoteID > 0 ? [...prevState.PendingChangeTypes, "VEHICLES"] : prevState.PendingChangeTypes,
          IsSaving: false,
        }), () => {
          this.props.history.push(`/Quote/Index/${this.state.QuoteID}`);
          this.updatePanels();
        });
      })
      .catch((e) => {
        this.setState({ IsSaving: false });
        // If not problem details
        if (!e?.status) alert("Unable to create quote!")
      });
  }

  private reAddQuote(deleteOffers: boolean) {

    if (deleteOffers && !window.confirm("Are you sure you want to CLEAR ALL offers?")) {
      return;
    }

    if (!deleteOffers && !window.confirm("Are you sure you want to KEEP ALL offers?")) {
      return;
    }

    if (this.state.IsTemplate && !window.confirm("Are you sure you want to update a template quote?")) {
      return;
    }

    if (this.state.Locations.length !== this.state.Stops.length) {
      alert("Please verify locations entered");
      return;
    }

    if (!this.state.QuoteData || this.state.QuoteData.Amount === 0) {
      alert("Unabled save quote without rate");
      return;
    }

    if (this.state.IsSaving) {
      return;
    }

    this.setState({ IsSaving: true });

    const data = {
      DeleteOffers: deleteOffers,
      QuoteID: this.state.PreviousQuoteID,
      ThreadID: this.state.ThreadID,
      RouteName: this.state.RouteName,
      Stops: this.state.Locations,
      Freight: this.state.Freight,
      LineItems: this.state.QuoteData.LineItems,
      RateComment: this.state.QuoteData.RateComment,
      SmartbidUsed: this.state.SmartbidDataUsed,
      SmartbidRateUpDown: this.state.SmartbidRateUpDown,
      SmartbidWinPercentagePrevious: this.state.SmartbidWinPercentagePrevious,
      SmartbidWinPercentageCurrent: this.state.SmartbidWinPercentageCurrent,
      VehicleTypeID: this.state.VehicleTypeID,
      BillingCustomerID: this.state.BillingCustomerID,
      AuthorizationCustomerID: this.state.AuthorizationCustomerID,
      AuthorizationCustomerContactID: this.state.AuthorizationCustomerContactID,
      BrokerID: this.state.BrokerID,
      BookingSource: this.state.BookingSource,
      DockHigh: this.state.DockHigh,
      LiftGate: this.state.LiftGate,
      AirRide: this.state.AirRide,
      HazMat: this.state.HazMat,
      FAST: this.state.FAST,
      TSA: this.state.TSA,
      OverrideDistance: this.state.OverrideDistance,
      Total: this.state.QuoteData.Amount,
      SearchRadius: this.state.Distance,
      Note: this.state.Note
    }

    this.setState(prevState => ({ QuoteID: prevState.PreviousQuoteID, PreviousQuoteID: 0 }));

    fetchApi('/api/Quote/ReSave', data, 'POST')
      .then((response: {
        QuoteID: number,
        QuoteStopIDs: number[],
        QuoteFreightIDs: number[],
        CreatedByUserName: string
      }) => {
        // Update QuoteStopID, QuoteFrieghtID
        this.state.Stops.forEach((stop, index) => {
          stop.QuoteStopID = response.QuoteStopIDs[index];
        });
        this.state.Freight.forEach((freight, index) => {
          freight.QuoteFreightID = response.QuoteFreightIDs[index];
        });
        this.setState(prevState => ({
          CreatedByUserName: response.CreatedByUserName,
          OfferData: deleteOffers ? null : prevState.OfferData,
          QuoteDataOutdated: false,
          PendingChangeTypes: deleteOffers ? [...prevState.PendingChangeTypes, "VEHICLES"] : prevState.PendingChangeTypes,
          IsSaving: false,
        }), () => {
          this.props.history.push(`/Quote/Index/${this.state.QuoteID}`);
          this.updatePanels();
        });
      })
      .catch((e) => {
        this.setState(prevState => ({ PreviousQuoteID: 0, QuoteID: prevState.PreviousQuoteID, IsSaving: false }));
        // If not problem details
        if (!e?.status) alert("Unable to recreate quote!");
      });
  }

  private selfAssign() {
    const data = {
      QuoteID: this.state.QuoteID
    }
    fetchApi('/api/Quote/SelfAssign', data, 'POST')
      .then((response: JsonResponse) => {
        if (response.Success) {
          this.setState({ Unassigned: false });
        } else {
          alert(response.ErrorMessage);
        }
      })
      .catch(() => {
        alert("Unable to self assign!")
      });
  }

  private openOrder() {
    this.props.history.push(`/Order/${this.state.OrderID}`);
  }

  private dispatchOrder() {
    this.props.history.push(`/Order/Planning/${this.state.OrderID}`);
  }

  private updateQuoteFromOrder() {
    if (!window.confirm("Are you sure this will CLEAR ALL offers?")) {
      return;
    }

    const data = {
      QuoteID: this.state.QuoteID,
      OrderID: this.state.OrderID
    };
    fetchApi('/api/Quote/UpdateQuoteFromOrder', data, 'POST')
      .then(() => {
        this.getQuote(this.state.QuoteID);
      })
      .catch(() => alert("Unable to update quote from order!"));
  }

  private openPersonalizedUrl() {
    if (!this.state.BillingCustomerID) {
      alert('Please select Billing Customer!');
      return;
    }

    if (!this.state.AuthorizationCustomerID) {
      alert('Please select Authorization Customer!');
      return;
    }

    this.setState({ ShowPersonalizedUrl: true });
  }

  private reserveQuote() {
    fetchApi('/api/Quote/ReserveQuote', {}, 'POST')
      .then((response: { QuoteID: number }) => {
        this.props.history.push(`/Quote/Index/${response.QuoteID}`);
      })
      .catch(() => alert("Unable to reserve quote!"));
  }

  private createPersonalizedUrl(options: { ShowRate: boolean }) {
    const data = {
      BillingCustomerID: this.state.BillingCustomerID,
      AuthorizationCustomerID: this.state.AuthorizationCustomerID,
      AuthorizationCustomerContactID: this.state.AuthorizationCustomerContactID,
      ShowRate: options.ShowRate,
    };
    fetchApi('/api/Quote/CreatePersonalizedURL', data, 'POST')
      .then((response: { URL: string }) => {
        navigator.clipboard.writeText(response.URL)
          .then(() => {
            this.setState({ ShowPersonalizedUrl: false });
            this.showSnackBarMessage("Copied! Use Ctrl+V to paste and share with a customer.");
          })
          .catch(() => alert("Unable to copy to clipboard!"));
      })
      .catch(() => alert("Unable to create personalized url!"));
  }

  private markTemplate() {
    const data = {
      QuoteID: this.state.QuoteID,
      IsTemplate: !this.state.IsTemplate,
    };
    fetchApi('/api/Quote/MarkTemplate', data, 'POST')
      .then(() => {
        this.setState({
          IsTemplate: data.IsTemplate
        })
      })
      .catch(() => alert("Unable to mark as template!"));
  }

  private dispatchQuote(vehicleID: number) {
    this.props.history.push(`/Order/Planning/${this.state.OrderID}/Vehicle/${vehicleID}`);
  }

  private cancelQuote(vehicleId: number) {
    if (this.state.QuoteID === 0) {
      alert("Please load/create quote first!");
      return;
    }

    const data = {
      QuoteID: this.state.QuoteID,
      VehicleID: vehicleId,
    }

    fetchApi('/api/Quote/CancelOfferDriver', data, 'POST')
      .then((response: JsonResponse) => {
        if (response.Success) {
          const vehicleValue = this.state.VehicleData.find(x => x.VehicleID == vehicleId);
          if (vehicleValue) {
            vehicleValue.QuoteOfferStatus = null;
            vehicleValue.QuoteOfferAllInRate = null;
            this.vehiclesPanel.current.forceUpdate();
          }
        } else {
          alert(`Error: ${response.ErrorMessage}`);
        }
      })
      .catch(() => alert("Unable to cancel quote offer!"));
  }

  private offerQuote(vehicle: VehicleValue, allInRate: number, linehaul: number, fuel: number) {
    if (this.state.QuoteID === 0) {
      alert("Please load/create quote first!");
      return;
    }

    if (allInRate <= 1) {
      alert("Please ensure there is a rate first!");
      return;
    }

    const data = {
      QuoteID: this.state.QuoteID,
      VehicleID: vehicle.VehicleID,
      ModNum: vehicle.ModNum,
      Rate: allInRate,
      LineHaul: linehaul,
      Fuel: fuel,
      DeadheadDistance: vehicle.Distance
    }

    fetchApi('/api/Quote/OfferDriver', data, 'POST')
      .then((response: JsonResponse) => {
        if (response.Success) {
          const vehicleValue = this.state.VehicleData.find(x => x.VehicleID == vehicle.VehicleID);
          if (vehicleValue) {
            vehicleValue.QuoteOfferStatus = 0;
            vehicleValue.QuoteOfferAllInRate = allInRate;
            this.vehiclesPanel.current.forceUpdate();
          }
        } else {
          alert(`Error: ${response.ErrorMessage}`);
        }
      })
      .catch(() => alert("Unable to offer quote!"));
  }

  private IsDomestic(): boolean {
    return this.state.Locations.filter(x => x.Location.Country != "US").length === 0;
  }

  private IsInternational(): boolean {
    return [...new Set(this.state.Locations.map(x => x.Location.Country))].length > 1;
  }

  private reOfferQuote(vehicle: VehicleValue, allInRate: number, linehaul: number, fuel: number) {
    if (this.state.QuoteID === 0) {
      alert("Please load/create quote first!");
      return;
    }

    if (allInRate <= 1) {
      alert("Please ensure there is a rate first!");
      return;
    }

    const data = {
      QuoteID: this.state.QuoteID,
      VehicleID: vehicle.VehicleID,
      ModNum: vehicle.ModNum,
      Rate: allInRate,
      LineHaul: linehaul,
      Fuel: fuel,
      DeadheadDistance: vehicle.Distance
    }

    fetchApi('/api/Quote/ReOfferDriver', data, 'POST')
      .then((response: JsonResponse) => {

        if (response.Success) {
          const vehicleValue = this.state.VehicleData.find(x => x.VehicleID == vehicle.VehicleID);
          if (vehicleValue) {
            vehicleValue.QuoteOfferStatus = 0;
            vehicleValue.QuoteOfferAllInRate = allInRate;
            this.vehiclesPanel.current.forceUpdate();
          }
        } else {
          alert(`Error: ${response.ErrorMessage}`);
        }
      })
      .catch(() => alert("Unable to re-offer quote!"));
  }

  private reserve(vehicleID: number) {
    const data = {
      VehicleID: vehicleID,
      Comment: (this.state.QuoteID > 0) ? `For Quote # ${this.state.QuoteID}` : 'For Quote'
    }

    fetchApi('/api/Quote/Reserve', data, 'POST')
      .then((response: JsonResponse) => {
        if (response.Success) {
          // Reload Vehicles
          if (this.state.Links.find((x) => x.Name === 'Vehicles')) {
            const data = {
              QuoteID: this.state.QuoteID,
              DateTime: this.state.Locations[0].DateTime,
              Locations: this.state.Locations.map(x => x.Location),
              VehicleTypeID: this.state.VehicleTypeID,
              Freight: this.state.Freight,
              DockHigh: this.state.DockHigh,
              LiftGate: this.state.LiftGate,
              AirRide: this.state.AirRide,
              HazMat: this.state.HazMat,
              FAST: this.state.FAST,
              TSA: this.state.TSA,
              Distance: this.state.Distance,
              Domestic: this.IsDomestic(),
              IncludeTriState: true,
            }
            const link = this.state.Links.find(x => x.Name === "Vehicles");
            fetchApi(link.Link, data, link.Method)
              .then((data) => this.setState({ VehicleData: data.Vehicles }, () => { this.populateHOSAlertsForLoadOneTrucks(); }));
          }
        } else {
          alert(response.ErrorMessage);
        }
      })
      .catch(() => alert("Unable to reserve vehicle!"));
  }

  private transfer(vehicleID: number) {
    const data = {
      VehicleID: vehicleID,
    }

    fetchApi('/api/Quote/Transfer', data, 'POST')
      .then((response: JsonResponse) => {
        if (response.Success) {
          // Reload Vehicles
          if (this.state.Links.find((x) => x.Name === 'Vehicles')) {
            const data = {
              QuoteID: this.state.QuoteID,
              DateTime: this.state.Locations[0].DateTime,
              Locations: this.state.Locations.map(x => x.Location),
              VehicleTypeID: this.state.VehicleTypeID,
              Freight: this.state.Freight,
              DockHigh: this.state.DockHigh,
              LiftGate: this.state.LiftGate,
              AirRide: this.state.AirRide,
              HazMat: this.state.HazMat,
              FAST: this.state.FAST,
              TSA: this.state.TSA,
              Distance: this.state.Distance,
              Domestic: this.IsDomestic(),
              IncludeTriState: true,
            }
            const link = this.state.Links.find(x => x.Name === "Vehicles");
            fetchApi(link.Link, data, link.Method)
              .then((data) => this.setState({ VehicleData: data.Vehicles }, () => { this.populateHOSAlertsForLoadOneTrucks(); }));
          }
        } else {
          alert(response.ErrorMessage);
        }
      })
      .catch(() => alert("Unable to transfer vehicle!"));
  }

  private release(vehicleID: number) {
    const data = {
      VehicleID: vehicleID,
    }

    fetchApi('/api/Quote/Release', data, 'POST')
      .then((response: JsonResponse) => {
        if (response.Success) {
          // Reload Vehicles
          if (this.state.Links.find((x) => x.Name === 'Vehicles')) {
            const data = {
              QuoteID: this.state.QuoteID,
              DateTime: this.state.Locations[0].DateTime,
              Locations: this.state.Locations.map(x => x.Location),
              VehicleTypeID: this.state.VehicleTypeID,
              Freight: this.state.Freight,
              DockHigh: this.state.DockHigh,
              LiftGate: this.state.LiftGate,
              AirRide: this.state.AirRide,
              HazMat: this.state.HazMat,
              FAST: this.state.FAST,
              TSA: this.state.TSA,
              Distance: this.state.Distance,
              Domestic: this.IsDomestic(),
              IncludeTriState: true,
            }
            const link = this.state.Links.find(x => x.Name === "Vehicles");
            fetchApi(link.Link, data, link.Method)
              .then((data) => this.setState({ VehicleData: data.Vehicles }, () => { this.populateHOSAlertsForLoadOneTrucks(); }));
          }
        } else {
          alert(response.ErrorMessage);
        }
      })
      .catch(() => alert("Unable to release vehicle!"));
  }

  private turndown(vehicleID: number, driverID: number, distance: number, reset: boolean) {
    const data = {
      QuoteID: this.state.QuoteID,
      OrderID: this.state.OrderID || null,
      DriverID: driverID,
      VehicleID: vehicleID,
      Reset: reset,
      Legs: this.state.Stops.length,
      LoadedDistance: this.state.TransitData ? Math.round(this.state.TransitData.Distance) : 0,
      EmptyDistance: Math.round(distance),
      Linehaul: this.state.QuoteData ? this.state.QuoteData.LineHaulAmount : 0,
      Accessorial: this.state.QuoteData ? this.state.QuoteData.AccessorialAmount : 0,
      Fuel: this.state.QuoteData ? this.state.QuoteData.FuelAmount : 0,
      Comment: 'Manual'
    }
    fetchApi('/api/Quote/CreateDriverTurnDown', data, 'POST')
      .then((response: JsonResponse) => {
        if (response.Success) {
          alert("Successfully created turndown!");
        } else {
          alert(response.ErrorMessage);
        }
      })
      .catch(() => alert("Unable to create turndown!"));
  }

  private updateDistance(newDistance: number) {
    this.setState({ Distance: newDistance }, () => {
      // Reload Vehicles
      if (this.state.Links.find((x) => x.Name === 'Vehicles')) {
        const data = {
          QuoteID: this.state.QuoteID,
          DateTime: this.state.Locations[0].DateTime,
          Locations: this.state.Locations.map(x => x.Location),
          VehicleTypeID: this.state.VehicleTypeID,
          Freight: this.state.Freight,
          DockHigh: this.state.DockHigh,
          LiftGate: this.state.LiftGate,
          AirRide: this.state.AirRide,
          HazMat: this.state.HazMat,
          FAST: this.state.FAST,
          TSA: this.state.TSA,
          Distance: this.state.Distance,
          Domestic: this.IsDomestic(),
          IncludeTriState: true,
        }
        const link = this.state.Links.find(x => x.Name === "Vehicles");
        fetchApi(link.Link, data, link.Method)
          .then((data) => this.setState({ VehicleData: data.Vehicles }, () => { this.populateHOSAlertsForLoadOneTrucks(); }));
      }
      if (this.state.AllianceVehicleData && this.state.Links.find((x) => x.Name === 'AllianceVehicles')) {
        const data = {
          Location: this.state.Locations[0].Location,
          Freight: this.state.Freight,
          Distance: this.state.Distance,
          HazMat: this.state.HazMat,
          DockHigh: this.state.DockHigh,
          LiftGate: this.state.LiftGate,
          IsInternational: this.IsInternational(),
          VehicleTypeID: this.state.VehicleTypeID
        }
        const link = this.state.Links.find(x => x.Name === "AllianceVehicles");
        fetchApi(link.Link, data, link.Method)
          .then((data) => this.setState({ AllianceVehicleData: data.Vehicles }));
      }
      if (this.state.CarrierData) {
        const data = {
          Location: this.state.Locations[0].Location,
          Distance: this.state.Distance,
          VehicleTypeID: this.state.VehicleTypeID
        }
        fetchApi('/api/Quote/Carriers', data, 'POST')
          .then((response) => this.setState({ CarrierData: response.Carriers }));
      }
    });
  }

  private callLoadOneTrucks() {
    const link = this.state.Links.find(x => x.Name === "Vehicles");
    if (link) {
      const validationHashVehicles: string = this.state.ValidationRequestHashVehicles;
      const data = {
        QuoteID: this.state.QuoteID,
        DateTime: this.state.Locations[0].DateTime,
        Locations: this.state.Locations.map(x => x.Location),
        VehicleTypeID: this.state.VehicleTypeID,
        Freight: this.state.Freight,
        DockHigh: this.state.DockHigh,
        LiftGate: this.state.LiftGate,
        AirRide: this.state.AirRide,
        HazMat: this.state.HazMat,
        FAST: this.state.FAST,
        TSA: this.state.TSA,
        Distance: this.state.Distance,
        Domestic: this.IsDomestic(),
        IncludeTriState: true,
      }
      this.setState({ VehicleDataIsLoading: true });
      fetchApi(link.Link, data, link.Method)
        .then((data) => {
          if (validationHashVehicles === this.state.ValidationRequestHashVehicles) {
            this.setState({ VehicleData: data.Vehicles, VehicleDataIsLoading: false }, () => { this.populateHOSAlertsForLoadOneTrucks(); })
          }
        })
        .catch(() => {
          if (validationHashVehicles === this.state.ValidationRequestHashVehicles) {
            this.setState({ VehicleData: null, VehicleDataIsLoading: false });
          }
        });
    }
  }

  private callSylectus() {
    const link = this.state.Links.find(x => x.Name === "AllianceVehicles")
    if (link) {
      const validationHashVehicles: string = this.state.ValidationRequestHashVehicles;
      const data = {
        Location: this.state.Locations[0].Location,
        Freight: this.state.Freight,
        Distance: this.state.Distance,
        HazMat: this.state.HazMat,
        DockHigh: this.state.DockHigh,
        LiftGate: this.state.LiftGate,
        IsInternational: this.IsInternational(),
        VehicleTypeID: this.state.VehicleTypeID
      }
      this.setState({ VehicleDataIsLoading: true });
      fetchApi(link.Link, data, link.Method)
        .then((data) => {
          if (validationHashVehicles === this.state.ValidationRequestHashVehicles) {
            this.setState({
              AllianceVehicleData: data.Vehicles, VehicleDataIsLoading: false
            })
          }
        })
        .catch(() => {
          if (validationHashVehicles === this.state.ValidationRequestHashVehicles) {
            alert("Unable to load Partner Units!");
            this.setState({ AllianceVehicleData: null, VehicleDataIsLoading: false });
          }
        });
    }
  }

  private getCarriers() {
    if (this.state.Locations[0]) {
      const validationHashVehicles: string = this.state.ValidationRequestHashVehicles;
      this.setState({ VehicleDataIsLoading: true });
      const data = {
        Location: this.state.Locations[0].Location,
        Distance: this.state.Distance,
        VehicleTypeID: this.state.VehicleTypeID
      }
      fetchApi('/api/Quote/Carriers', data, 'POST')
        .then((response) => {
          if (validationHashVehicles === this.state.ValidationRequestHashVehicles) {
            this.setState({ CarrierData: response.Carriers, VehicleDataIsLoading: false })
          }
        })
        .catch(() => {
          if (validationHashVehicles === this.state.ValidationRequestHashVehicles) {
            alert("Unable to load Carriers Nearby!");
            this.setState({ CarrierData: null, VehicleDataIsLoading: false });
          }
        });
    }
  }

  private getFedExRates() {
    if (this.state.Locations[0]) {
      const validationHashExternalRates: string = this.state.ValidationRequestHashExternalRates;
      this.setState({ VehicleDataIsLoading: true });
      const data = {
        Locations: this.state.Locations.map(x => x.Location),
        FromDateTime: this.state.Stops[0].DateTime,
        Freight: this.state.Freight
      }
      fetchApi('/api/Quote/FedExRates', data, 'POST')
        .then((response) => {
          if (validationHashExternalRates === this.state.ValidationRequestHashExternalRates) {
            this.setState({ FedExRateData: response, VehicleDataIsLoading: false })
          }
        })
        .catch(() => {
          if (validationHashExternalRates === this.state.ValidationRequestHashExternalRates) {
            alert("Unable to load FedEx Rates!");
            this.setState({ FedExRateData: null, VehicleDataIsLoading: false });
          }
        });
    }
  }

  private getEShippingRates(commodityTypeID: number) {

    //if (this.state.Locations[0] && this.state.CommodityTypeID > 0) {
    if (this.state.Locations[0] && commodityTypeID > 0) {
      const validationHashExternalRates: string = this.state.ValidationRequestHashExternalRates;
      this.setState({ VehicleDataIsLoading: true });
      const data = {
        Locations: this.state.Locations.map(x => x.Location),
        FromDateTime: this.state.Stops[0].DateTime,
        Freight: this.state.Freight,
        LiftGate: this.state.LiftGate,
        CommodityCode: commodityTypeID //this.state.CommodityTypeID
      }
      fetchApi('/api/Quote/EShippingRates', data, 'POST')
        .then((response) => {
          if (validationHashExternalRates === this.state.ValidationRequestHashExternalRates) {
            this.setState({ EShippingRateData: response, VehicleDataIsLoading: false })
          }
        })
        .catch(() => {
          if (validationHashExternalRates === this.state.ValidationRequestHashExternalRates) {
            alert("Unable to load EShip Rates!");
            this.setState({ EShippingRateData: null, VehicleDataIsLoading: false });
          }
        });
    }
  }

  private getUPSRates(commodityTypeID: number) {
    if (this.state.Locations[0] && commodityTypeID > 0) {
      const validationHashExternalRates: string = this.state.ValidationRequestHashExternalRates;
      this.setState({ VehicleDataIsLoading: true });
      const data = {
        Locations: this.state.Locations.map(x => x.Location),
        FromDateTime: this.state.Stops[0].DateTime,
        Freight: this.state.Freight,
        LiftGate: this.state.LiftGate,
        Hazmat: this.state.HazMat,
        CommodityCode: commodityTypeID //this.state.CommodityTypeID
      }
      fetchApi('/api/Quote/UPSRates', data, 'POST')
        .then((response) => {
          if (validationHashExternalRates === this.state.ValidationRequestHashExternalRates) {
            this.setState({ UPSRateData: response, VehicleDataIsLoading: false })
          }
        })
        .catch(() => {
          if (validationHashExternalRates === this.state.ValidationRequestHashExternalRates) {
            alert("Unable to load UPS Rates!");
            this.setState({ UPSRateData: null, VehicleDataIsLoading: false });
          }
        });
    }
  }

  private getDHLRates() {
    if (this.state.Locations[0]) {
      const validationHashExternalRates: string = this.state.ValidationRequestHashExternalRates;
      this.setState({ VehicleDataIsLoading: true });
      const data = {
        Locations: this.state.Locations.map(x => x.Location),
        FromDateTime: this.state.Stops[0].DateTime,
        Freight: this.state.Freight,
        LiftGate: this.state.LiftGate
      }
      fetchApi('/api/Quote/DHLRates', data, 'POST')
        .then((response) => {
          if (validationHashExternalRates === this.state.ValidationRequestHashExternalRates) {
            this.setState({ DHLRateData: response, VehicleDataIsLoading: false })
          }
        })
        .catch(() => {
          if (validationHashExternalRates === this.state.ValidationRequestHashExternalRates) {
            alert("Unable to load DHL Rates!");
            this.setState({ DHLRateData: null, VehicleDataIsLoading: false });
          }
        });
    }
  }

  private fetchWinPercentageChart() {
    if (!this.state.QuoteData || this.state.QuoteData.LineHaulAmount === 0) {
      alert("Please enter a rate first!");
      return Promise.reject();
    } else if (!this.state.TransitData || this.state.TransitData.Distance === 0) {
      alert("No distance available to calculate win percentage!");
      return Promise.reject();
    }
    if (this.state.Locations[0] && this.state.TransitData) {
      const existingFuels = this.state.QuoteData.LineItems.filter(x => x.RateDescriptionID === 5000 && x.Rate > 0);
      const data = {
        Quote: {
          QuoteID: this.state.QuoteID || this.state.PreviousQuoteID,
          BillingCustomerID: this.state.BillingCustomerID || this.state.AuthorizationCustomerID,
          VehicleTypeID: this.state.VehicleTypeID,
          DateTime: this.state.Locations[0].DateTime,
          FromZip: this.state.Locations[0].Location.Zip,
          ToZip: this.state.Locations[this.state.Locations.length - 1].Location.Zip,
          Distance: this.state.TransitData.Distance,
          TotalWeight: this.state.Freight.reduce((a, b) => a + b.Weight, 0),
          TotalPieces: this.state.Freight.reduce((a, b) => a + b.Pieces, 0),
          RatePerMile: this.state.QuoteData.LineHaulAmount / this.state.TransitData.Distance,
          FlatFuelSurcharge: existingFuels.length > 0 ? existingFuels[0].Amount : null,
          FuelRate: this.state.FuelRate
        }
      }
      return fetchApi('/api/Quote/WinPercentageChart', data, 'POST')
        .then((response: WinPercentageChartValue) => {
          this.setState({ WinPercentageData: response });
        })
        .catch(() => {
          alert("Error loading Win Percentage Chart");
          this.setState({ WinPercentageData: null });
        });
    }
    else {
      alert("Insufficient data to show Win Percentage Chart");
      this.setState({ WinPercentageData: null });
      return Promise.reject();
    }
  }

  private fetchWinPercentage(validationHashRate: string) {
    if (!this.state.QuoteData || this.state.QuoteData.LineHaulAmount === 0) {
      this.setState({ SmartbidData: null, SmartbidDataIsLoading: false });
      return;
    } else if (!this.state.TransitData || this.state.TransitData.Distance === 0) {
      this.setState({ SmartbidData: null, SmartbidDataIsLoading: false });
      return;
    }
    this.setState({ SmartbidDataIsLoading: true });
    const existingFuels = this.state.QuoteData.LineItems.filter(x => x.RateDescriptionID === 5000 && x.Rate > 0);
    const data = {
      QuoteID: this.state.QuoteID || this.state.PreviousQuoteID,
      BillingCustomerID: this.state.BillingCustomerID,
      VehicleTypeID: this.state.VehicleTypeID,
      DateTime: this.state.Locations[0].DateTime,
      FromZip: this.state.Locations[0].Location.Zip,
      ToZip: this.state.Locations[this.state.Locations.length - 1].Location.Zip,
      Distance: this.state.TransitData.Distance,
      TotalWeight: this.state.Freight.reduce((a, b) => a + b.Weight, 0),
      TotalPieces: this.state.Freight.reduce((a, b) => a + b.Pieces, 0),
      RatePerMile: this.state.QuoteData.LineHaulAmount / this.state.TransitData.Distance,
      FlatFuelSurcharge: existingFuels.length > 0 ? existingFuels[0].Amount : null,
      FuelRate: this.state.FuelRate
    }
    const link = this.state.Links.find(x => x.Name === 'SmartbidRate');
    fetchApi(link.Link, { Quote: data }, link.Method)
      .then((data: SmartbidValue) => {
        if (this.state.ValidationRequestHashRate === validationHashRate) {
          this.setState({ SmartbidData: data, SmartbidDataIsLoading: false });
        }
      })
      .catch(() => {
        if (this.state.ValidationRequestHashRate === validationHashRate) {
          this.setState({ SmartbidData: null, SmartbidDataIsLoading: false });
        }
      });
  }

  private getOffers() {
    if (this.state.QuoteID > 0) {
      this.setState({ OfferDataIsLoading: true, OfferData: [] });
      const data = {
        QuoteID: this.state.QuoteID
      }
      return fetchApi('/api/Quote/Offers', data, 'POST')
        .then((response) => this.setState({ OfferData: response.Offers }))
        .finally(() => this.setState({ OfferDataIsLoading: false }));
    }
  }

  private openLogs() {
    this.setState({ ShowAuditLogs: true });
  }

  private openStatusUpdate() {
    this.setState({ ShowStatusUpdate: true });
  }

  private reply() {
    this.setState({ ShowFrontReply: true });
  }

  private copyEmailTemplate(vehicleID?: number) {
    if (vehicleID) {
      const vehicleValue = this.state.VehicleData.find(x => x.VehicleID == vehicleID);
      this.setState({ QuoteEmailTemplate: true, QuoteEmailVehicle: vehicleValue });
    } else {
      this.setState({ QuoteEmailTemplate: true });
    }

    this.showSnackBarMessage("Copied! Use Ctrl+V to paste into email.");
  }

  private copyEmailTemplateCarrier(offer: OfferValue) {
    this.setState({ QuoteEmailTemplateCarrier: offer });

    this.showSnackBarMessage("Copied! Use Ctrl+V to paste into email.");
  }

  private emailCarrier(offer: OfferValue) {
    // Include City, State and To City, State in subject line
    window.location.href = `mailto:${offer.ContactEmail}?subject=Re:%20Quote%20%23%20${this.state.QuoteID}%20-%20${this.state.Locations[0].Location.City}%2C%20${this.state.Locations[0].Location.State}%20to%20${this.state.Locations[this.state.Locations.length - 1].Location.City}%2C%20${this.state.Locations[this.state.Locations.length - 1].Location.State}`;
  }

  private awardCarrier(offer: OfferValue) {
    var link = offer.Links.find(x => x.Name == 'Award');
    fetchApi(link.Link, {}, link.Method)
      .then(() => {
        this.getOffers();
      });
  }

  private rejectAllCarriers() {
    fetchApi(`/api/Quote/RejectAllCarriers/${this.state.QuoteID}`, {}, 'POST')
      .then(() => {
        this.getOffers();
      });
  }

  private openDeadhead(coordinates: Coordinate) {
    openWindow(`/map.html?stop=${coordinates.Latitude},${coordinates.Longitude}&stop=${this.state.Locations[0].Location.Coordinates.Latitude},${this.state.Locations[0].Location.Coordinates.Longitude}`);
  }

  private selectCarrierOffer(total: number, carrierOfferId: number) {
    if (this.state.QuoteID == 0) {
      alert("Please save quote first!");
      return;
    }
    this.setState({ ShowFrontReply: true, BidAmount: total, SelectedCarrierOfferID: carrierOfferId });
  }

  private showSnackBarMessage(message: string) {
    this.setState({ SnackBarText: message });

    // After 3 seconds, remove the show class from DIV
    setTimeout(() => {
      this.setState({ SnackBarText: '' })
    }, 3000);
  }

  private openReviews(allianceVehicle: AllianceVehicleValue | CarrierValue) {
    this.setState({ CarrierReviewName: allianceVehicle.CarrierName, CarrierReviewPhone: allianceVehicle.CarrierPhone })
  }

  private clickQuote(quoteID: number) {
    this.props.history.push(`/Quote/Index/${quoteID}`);
  }
}

export default Quote;
