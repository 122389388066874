import { useEffect, useState } from "react";
import { ViewModel } from "TypeGen/Assets/ComplianceDates/view-model";
import { VehicleComplianceViewModel } from "TypeGen/Assets/ComplianceDates/vehicle-compliance-view-model";
import { DriverComplianceViewModel } from "TypeGen/Assets/ComplianceDates/driver-compliance-view-model";
import { Grid, GridColumn as Column, GridCellProps } from "@progress/kendo-react-grid";
import { Link } from "react-router-dom";
import LocalDateOnlyCell from "components/cells/LocalDateOnlyCell";
import moment from "moment";
import CenterDivPanel from "components/CenterDivPanel";
import { Loader } from "@progress/kendo-react-indicators";
import { Title } from '../../utils/title';
import { fetchApi } from "services/api";
import { TrailerComplianceViewModel } from "TypeGen/Assets/ComplianceDates/trailer-compliance-view-model";
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from "@progress/kendo-react-layout";
import { SortDescriptor, orderBy } from "@progress/kendo-data-query";

const ComplianceDateCell = (props: GridCellProps) => {
  if (!props.field || props.rowType !== 'data') return null;
  if (!props.dataItem[props.field]) return <td>N/A</td>

  const date = moment(props.dataItem[props.field]);
  const currentDate = moment();
  const expired = date < currentDate;
  const nearlyExpired = !expired && date < currentDate.add(15, 'days');

  return <td style={{ backgroundColor: expired ? '#ba000d' : nearlyExpired ? '#ffeb3b' : undefined, color: nearlyExpired ? '#000000' : undefined }}>
    {date.format("MM/DD/YYYY")}
  </td>
}


const defaultVehicleSort = [{ field: "VehicleNumber", dir: "asc" } as SortDescriptor];
const defaultDriverSort = [{ field: "DriverNumber", dir: "asc" } as SortDescriptor];
const defaultTrailerSort = [{ field: "TrailerNumber", dir: "asc" } as SortDescriptor];

const ComplianceDates = () => {
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState(0);
  const handleSelect = (e: TabStripSelectEventArguments) => setSelected(e.selected);
  const [vehicles, setVehicles] = useState<VehicleComplianceViewModel[]>([]);
  const [drivers, setDrivers] = useState<DriverComplianceViewModel[]>([]);
  const [trailers, setTrailers] = useState<TrailerComplianceViewModel[]>([]);
  const [vehicleSort, setVehicleSort] = useState<SortDescriptor[]>(defaultVehicleSort);
  const [driverSort, setDriverSort] = useState<SortDescriptor[]>(defaultDriverSort);
  const [trailerSort, setTrailerSort] = useState<SortDescriptor[]>(defaultTrailerSort);

  useEffect(() => {
    fetchApi("/api/Asset/ComplianceDates")
      .then((data: ViewModel) => {
        setVehicles(data.Vehicles);
        setDrivers(data.Drivers);
        setTrailers(data.Trailers);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return <>
    <Title string="Compliance Dates" />
    {loading && <CenterDivPanel>
      <Loader type="converging-spinner" />
    </CenterDivPanel>}
    <br />
    <TabStrip selected={selected} onSelect={handleSelect} animation={false}>
      <TabStripTab title="Vehicles">
        <Grid
          sortable
          data={orderBy(vehicles, vehicleSort)}
          sort={vehicleSort}
          onSortChange={(e) => setVehicleSort(e.sort)}
          style={{ height: `${window.innerHeight - 204}px` }}
        >
          <Column field="VehicleNumber" title="Vehicle #" cell={(e) => <td>
            <Link style={{ color: "#007bff" }} to={`/Assets/Vehicle/${e.dataItem.VehicleID}`}>{e.dataItem.VehicleNumber}</Link>
          </td>} />
          <Column field="DriverNumber" title="Driver #" />
          <Column field="LicenseExpiryDate" title="License Expiry" cell={ComplianceDateCell} />
          <Column field="LastMaintenanceDate" title="Last PM" cell={LocalDateOnlyCell} />
          <Column field="NextMaintenanceDate" title="Next PM" cell={ComplianceDateCell} />
          <Column field="InsuranceExpiryDate" title="Insurance Expires" cell={ComplianceDateCell} />
          <Column field="LastInspectionDate" title="Last DOT Insp." cell={LocalDateOnlyCell} />
          <Column field="NextInspectionDate" title="Next DOT Insp." cell={ComplianceDateCell} />
          <Column field="CaliforniaExpiryDate" title="Cali. CTC Expires" cell={ComplianceDateCell} />
        </Grid>
      </TabStripTab>
      <TabStripTab title="Drivers">
        <Grid
          sortable
          data={orderBy(drivers, driverSort)}
          sort={driverSort}
          onSortChange={(e) => setDriverSort(e.sort)}
          style={{ height: `${window.innerHeight - 204}px` }}
        >
          <Column field="DriverNumber" title="Driver #" cell={(e) => <td>
            <Link style={{ color: "#007bff" }} to={`/Assets/Driver/${e.dataItem.DriverID}`}>{e.dataItem.DriverNumber}</Link>
          </td>} />
          <Column field="VehicleNumber" title="Vehicle #" />
          <Column field="DriversLicenseExpiryDate" title="License Expiry" cell={ComplianceDateCell} />
          <Column field="NextPhysicalDate" title="Next Physical" cell={ComplianceDateCell} />
          <Column field="MVRTestDueDate" title="MVR Test Due" cell={ComplianceDateCell} />
          <Column field="WorkersCompensationExpiryDate" title="Worker's Comp. Expires" cell={ComplianceDateCell} />
          <Column field="HazmatCertificationExpiryDate" title="Hazmat Expires" cell={ComplianceDateCell} />
          <Column field="FastExpiryDate" title="FAST Expires" cell={ComplianceDateCell} />
          <Column field="CSAExpiryDate" title="CSA Expires" cell={ComplianceDateCell} />
          <Column field="TSAExpiryDate" title="TSA Expires" cell={ComplianceDateCell} />
          <Column field="TWICCertificationExpiryDate" title="TWIC Expires" cell={ComplianceDateCell} />
          <Column field="WorkVisaExpiryDate" title="Work Visa Expires" cell={ComplianceDateCell} />
          <Column field="PassportExpiryDate" title="Passport Expires" cell={ComplianceDateCell} />
        </Grid>
      </TabStripTab>
      <TabStripTab title="Trailers">
        <Grid
          sortable
          data={orderBy(trailers, trailerSort)}
          sort={trailerSort}
          onSortChange={(e) => setTrailerSort(e.sort)}
          style={{ height: `${window.innerHeight - 204}px` }}
        >
          <Column field="TrailerNumber" title="Trailer #" cell={(e) => <td>
            <Link style={{ color: "#007bff" }} to={`/Assets/Trailer/${e.dataItem.TrailerID}`}>{e.dataItem.TrailerNumber}</Link>
          </td>} />
          <Column field="VehicleNumber" title="Vehicle #" />
          <Column field="DriverNumber" title="Driver #" />
          <Column field="LicenseExpiryDate" title="License Expiry" cell={ComplianceDateCell} />
          <Column field="InsuranceExpiryDate" title="Insurance Expires" cell={ComplianceDateCell} />
          <Column field="LeaseExpiryDate" title="Lease Expires" cell={ComplianceDateCell} />
          <Column field="NextInspectionDate" title="Next Inspection" cell={ComplianceDateCell} />
          <Column field="NextPMDate" title="Next PM" cell={ComplianceDateCell} />
        </Grid>
      </TabStripTab>
    </TabStrip>
  </>;
};

export default ComplianceDates;