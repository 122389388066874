import { formatNumber } from '@progress/kendo-intl';
import { Button, DropDownButton } from "@progress/kendo-react-buttons";
import { SvgIcon } from "@progress/kendo-react-common";
import { Loader } from "@progress/kendo-react-indicators";
import { chevronRightIcon, gearIcon, trashIcon } from "@progress/kendo-svg-icons";
import OrderLinkToQuote from "components/OrderLinkToQuote";
import RouteMap from "components/RouteMap";
import { getSplitLink } from "components/SplitIcon";
import useConfirm from "components/useConfirm";
import { default as Moment, default as moment } from 'moment-timezone';
import { createContext, useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import { openWindow } from "services/openWindow";
import { JsonResponse } from "TypeGen/json-response";
import { ViewModel } from "TypeGen/Order/Details/view-model";
import { useBoolean } from "usehooks-ts";
import CustomerProfile from "views/Customers/Profile";
import TripSummary from "views/Track/TripSummary";
import LoadingPanel from "../../../components/LoadingPanel";
import { fetchApi } from "../../../services/api";
import realFormatter from "../../../services/formatting/number";
import { ApiLink } from '../../../TypeGen/api-link';
import { Title } from "../../../utils/title";
import Booked from "../Booked";
import Copy from "../Copy";
import SplitOrderSummary from "../SplitOrderSummary";
import TransitTime from "../TransitTime";
import Turndown from "../Turndown";

type RouteComponentParams = {
  orderId: string;
  sequence?: string;
  letter?: string;
};

type OrderContextType = {
  activeStopIds: number[];
  deleteStopIds: number[];
  refresh: () => void;
  setActiveStopIds: (stopIds: number[]) => void;
  setDeleteStopIds: (stopIds: number[]) => void;
  toggleMap: () => void;
}

export const OrderContext = createContext<OrderContextType>({
  activeStopIds: [],
  deleteStopIds: [],
  refresh: () => { },
  setActiveStopIds: () => { },
  setDeleteStopIds: () => { },
  toggleMap: () => { },
});

type Props = {
  children?: React.ReactNode
};

const OrderDetails: React.FC<Props> = props => {
  const { orderId: orderIdParam, sequence: sequenceParam, letter: letterParam } = useParams<RouteComponentParams>();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const { value: showMap, toggle: toggleMap } = useBoolean(false);
  const { value: showTransitTime, toggle: toggleTransitTime } = useBoolean(false);
  const { value: showTurndown, toggle: toggleTurndown } = useBoolean(false);
  const { value: showBooked, toggle: toggleBooked } = useBoolean(false);
  const { value: showCopy, toggle: toggleCopy } = useBoolean(false);
  const { value: showLinkQuote, toggle: toggleLinkQuote, setFalse: hideLinkQuote } = useBoolean(false);
  const { value: showTripSummary, toggle: toggleTripSummary } = useBoolean(false);
  const [orderStatus, setOrderStatus] = useState<number>(0);
  const [details, setDetails] = useState<ViewModel>();
  const [activeStopIds, setActiveStopIds] = useState<number[]>([]);
  const [deleteStopIds, setDeleteStopIds] = useState<number[]>([]);
  const [showOrderSummaryOrderID, setShowOrderSummaryOrderID] = useState(0);
  const [showCustomerID, setShowCustomerID] = useState(0);
  const { ConfirmationDialog, confirm } = useConfirm({});

  const refreshOrderDetails = useCallback(() => {
    setLoading(true);

    const url = `/api/Order/Details/${orderIdParam}${letterParam ? `/${sequenceParam}/${letterParam}` : ''}`;

    fetchApi(url)
      .then((response: ViewModel) => {
        setDetails(response);
        setLoading(false);
      })
      .catch(err => {
        alert(err);
        setLoading(false);
      });
  }, [orderIdParam, letterParam, sequenceParam]);

  useEffect(() => {
    refreshOrderDetails();
  }, [orderIdParam, refreshOrderDetails]);

  const copy = async (firstScheduleDateTime: Date | null, copyCount: number) => {
    setLoading(true);
    const command = details.Links.find(l => l.Name === 'Copy');
    const data = {
      OrderStatus: 95,
      FirstScheduleDateTime: firstScheduleDateTime,
      CopyCount: copyCount
    }
    fetchApi(command.Link, data, command.Method)
      .then((response: JsonResponse) => {
        if (response.Success) {
          toggleCopy();

          const link = response.Links.find(l => l.Name === 'Order');
          if (link) {
            history.push(link.Link);
          } else {
            alert(`Successfully copied order ${copyCount} times`);
          }
        } else {
          alert(response.ErrorMessage);
        }
      })
      .catch(e => {
        // If not problem details
        if (!e?.status) alert('Unable to copy order');
      })
      .finally(() => setLoading(false));
  }

  const changeStatus = (orderStatus: number, reason?: number) => {
    setLoading(true);

    const data = {
      OrderStatus: orderStatus,
      TurndownReasonID: reason
    }

    const command = details.Links.find(l => l.Name === 'UpdateStatus');
    fetchApi(command.Link, data, command.Method)
      .then(() => {
        setLoading(false);
        refreshOrderDetails();
      })
      .catch(e => {
        setLoading(false);
        // If not problem details
        if (!e?.status) alert('Unable to change status');
      });
  }

  const changeBookUser = (userId: number) => {
    setLoading(true);

    const data = {
      BookUserID: userId
    }

    const command = details.Links.find(l => l.Name === 'UpdateBookUser');
    fetchApi(command.Link, data, command.Method)
      .then(() => {
        setLoading(false);
        refreshOrderDetails();
      })
      .catch(e => {
        setLoading(false);
        // If not problem details
        if (!e?.status) alert('Unable to change book user');
      });
  }

  const createQuote = async () => {
    if (!await confirm('Are you sure you want to create a quote from this order?')) return;

    const command = details.Links.find(l => l.Name === 'CreateQuote');
    fetchApi(command.Link, {}, command.Method)
      .then((response: { QuoteID: number }) => {
        hideLinkQuote();
        refreshOrderDetails();
      })
      .catch(() => {
        alert("Unable to create quote!")
      });
  }

  const linkQuote = async (quoteId: number) => {
    if (!await confirm(`Are you sure you want to link quote #${quoteId} to this order?`)) return;

    const command = details.Links.find(l => l.Name === 'LinkQuote');
    fetchApi(command.Link, { QuoteID: quoteId }, command.Method)
      .then(() => {
        hideLinkQuote();
        refreshOrderDetails();
      })
      .catch(() => {
        alert("Unable to link quote!")
      });
  }

  const ediLoadTenderResponse = async (accept: boolean) => {
    if (!await confirm(`Are you sure you want to ${accept ? 'accept' : 'reject'} this EDI load tender?`)) return;

    setLoading(true);

    const command = details.Links.find(l => l.Name === 'EdiLoadTenderResponse');
    fetchApi(command.Link, { LoadTenderResponseAccepted: accept }, command.Method)
      .then((response: JsonResponse) => {
        if (response.Success === false)
          alert(response.ErrorMessage);
        refreshOrderDetails();
      })
      .catch(() => {
        alert("Unable to update!")
      }).finally(() => setLoading(false));
  }

  const updateInvoiceStop = (link: ApiLink) => {
    setLoading(true);

    let data = { ShowStopOnInvoice: true };
    if (link.Name === 'HideInvoiceStop')
      data.ShowStopOnInvoice = false;

    fetchApi(link.Link, data, link.Method)
      .then(() => {
        refreshOrderDetails();
      })
      .catch(() => {
        alert("Unable to update invoice stop!")
      }).finally(() => setLoading(false));
  }

  if (loading && !details) {
    return <div className="d-flex justify-content-center mt-5">
      <Loader type="converging-spinner" />
    </div>
  }

  const actionMenuItems: Array<{ text: string, icon?: string, onClick: () => void }> = [
    { text: "Images", onClick: () => history.push(`/Order/${orderIdParam}/Images`) },
    { text: showMap ? "Hide Map" : "Show Map", onClick: () => toggleMap() },
    { text: "BOL", onClick: () => openWindow(`/BillOfLading/View/${orderIdParam}`, 920, 1100) },
    { text: "Transaction Logs", onClick: () => history.push(`/Order/${orderIdParam}/TransactionLogs`) },
  ];

  if (details.Links.find(x => x.Name === "Copy")) {
    actionMenuItems.unshift({ text: "Copy Order", onClick: toggleCopy });
  }

  if (details.Links.find(x => x.Name === "Schedule")) {
    actionMenuItems.unshift({ text: "Schedule", onClick: () => history.push(`/Order/${orderIdParam}/Schedule`) });
  }

  if (details.Links.find(x => x.Name === "AddPair")) {
    actionMenuItems.unshift({ text: "Add Pair", onClick: () => history.push(`/Order/${orderIdParam}/AddPair`) })
  }

  if (details.Links.find(x => x.Name === "Dispatch")) {
    actionMenuItems.unshift({ text: "Dispatch", onClick: () => history.push(`/Order/Planning/${orderIdParam}`) });
  }

  if (details.Links.find(x => x.Name === "TripSummary")) {
    actionMenuItems.push({ text: "Trip Summary", onClick: toggleTripSummary });
  }

  const masterLink = details.Links.find(x => x.Name === "Master");
  if (masterLink) {
    actionMenuItems.push({ text: "Master", onClick: () => history.push(masterLink.Link) });
  }

  if (details.Links.find(x => x.Name === "CreateQuote")) {
    actionMenuItems.push({ text: "Create Quote", onClick: createQuote });
  }

  // Leave out for now as it's incomplete/confusing
  //actionMenuItems.push({ text: "API Integration", onClick: () => history.push(`/Order/${orderIdParam}/Integrations`) });

  return (
    <div className="container-xl">
      <Title string={`${details.OrderNumber}`} />
      {loading && <LoadingPanel />}
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark mt-3">
        <span className="navbar-brand">
          {details.QuoteID
            ? <Link to={`/Quote/Index/${details.QuoteID}`}>Quote # {details.QuoteID}</Link>
            : orderIdParam ? <a href="#" className="text-danger" onClick={(e) => { e.preventDefault(); toggleLinkQuote(); }}>
              Find Quote
            </a>
              : null}
          {showLinkQuote && <OrderLinkToQuote
            OrderID={parseInt(orderIdParam)}
            close={hideLinkQuote}
            createQuote={createQuote}
            linkQuote={linkQuote}
          />}
          <SvgIcon icon={chevronRightIcon} className="text-muted" />
          <Link to={`/Order/${orderIdParam}`}>Order # {details.OrderNumber}</Link>
        </span>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#orderNav" aria-controls="orderNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="orderNav">
          <ul className="navbar-nav">
          </ul>
          <ul className="nav navbar-nav ml-auto">
            <Button onClick={toggleBooked} title="Booked">
              {details.BookedByUserName} {moment.utc(details.BookedDateTime).fromNow()}
            </Button>
            &nbsp;&nbsp;
            <Button
              themeColor="success"
              onClick={() => {
                const link = details.Links.find(x => x.Name === 'Rate');
                history.push(link.Link);
              }}
            >
              {formatNumber(details.RateAmount, "c")}
            </Button>
            &nbsp;&nbsp;
            <DropDownButton
              text={details.OrderStatus}
              themeColor="secondary"
              textField="Name"
              items={details.OrderStatuses}
              disabled={details.OrderStatuses.length === 0}
              onItemClick={async (e) => {
                setOrderStatus(e.item.ID);
                if (e.item.ID == 160 || e.item.ID == 170) {
                  toggleTurndown();
                } else {
                  if (await confirm('Are you sure you want to change the status of this order?')) {
                    changeStatus(e.item.ID);
                  }
                }
              }}
            />
            {details.Links.find(x => x.Name === "EdiLoadTenderResponse") && <>
              &nbsp;&nbsp;
              <DropDownButton
                textField="text"
                items={
                  [
                    { text: "Accept Load Tender", value: true },
                    { text: "Reject Load Tender", value: false }
                  ]}
                text="EDI Load Tender Reply"
                themeColor="warning"
                onItemClick={(e) => ediLoadTenderResponse(e.item.value)}
              />
            </>}
            &nbsp;&nbsp;
            <DropDownButton
              text="Actions"
              themeColor="secondary"
              onItemClick={(e) => {
                actionMenuItems[e.itemIndex].onClick()
              }}
              items={actionMenuItems}
            />
          </ul>
        </div>
      </nav>
      <div className="card my-2">
        <table className="table table-hover mb-0">
          <thead>
            <tr>
              <th className="border-top-0"></th>
              <th className="border-top-0">Event</th>
              <th className="border-top-0">Scheduled (EST)</th>
              <th className="border-top-0">Customer</th>
              <th className="border-top-0">Address</th>
              <th className="border-top-0">City, State Zip</th>
              <th className="border-top-0">Miles</th>
              <th className="border-top-0">Pieces</th>
              <th className="border-top-0">Weight</th>
              <th className="border-top-0">Status</th>
            </tr>
          </thead>
          <tbody>
            {details?.Stops.map(stop => {
              const actionMenuItems: Array<{ text: string, icon?: string, onClick: () => void }> = [];
              if (stop.Links.find(x => x.Name === 'AddPickup')) {
                actionMenuItems.push({
                  text: 'Add Pickup',
                  icon: 'plus',
                  onClick: () => history.push(stop.Links.find(x => x.Name === 'AddPickup').Link)
                });
              }
              if (stop.Links.find(x => x.Name === 'AddDelivery')) {
                actionMenuItems.push({
                  text: 'Add Delivery',
                  icon: 'plus',
                  onClick: () => history.push(stop.Links.find(x => x.Name === 'AddDelivery').Link)
                });
              }
              if (stop.Links.find(x => x.Name === 'AddPair')) {
                actionMenuItems.push({
                  text: 'Add Pair',
                  icon: 'plus',
                  onClick: () => history.push(stop.Links.find(x => x.Name === 'AddPair').Link)
                });
              }
              if (stop.Links.find(x => x.Name === 'EditSplit')) {
                actionMenuItems.push({
                  text: 'Modify Split',
                  icon: 'pencil',
                  onClick: () => history.push(stop.Links.find(x => x.Name === 'EditSplit').Link)
                });
              }
              if (stop.Links.find(x => x.Name === 'Split')) {
                actionMenuItems.push({
                  text: 'Insert Split',
                  icon: 'login',
                  onClick: () => history.push(stop.Links.find(x => x.Name === 'Split').Link)
                });
              }
              if (stop.Links.find(x => x.Name === 'Delete')) {
                actionMenuItems.push({
                  text: 'Delete',
                  icon: 'trash',
                  onClick: () => history.push(stop.Links.find(x => x.Name === 'Delete').Link)
                });
              }

              if (stop.Links.find(x => x.Name === 'ShowInvoiceStop')) {
                actionMenuItems.push({
                  text: 'Show Stop On Invoice',
                  icon: '',
                  onClick: () => updateInvoiceStop(stop.Links.find(x => x.Name === 'ShowInvoiceStop'))
                });
              }

              if (stop.Links.find(x => x.Name === 'HideInvoiceStop')) {
                actionMenuItems.push({
                  text: 'Hide Stop From Invoice',
                  icon: '',
                  onClick: () => updateInvoiceStop(stop.Links.find(x => x.Name === 'HideInvoiceStop'))
                });
              }

              const activeStop = activeStopIds.includes(stop.OrderStopID);
              const deleteStop = deleteStopIds.includes(stop.OrderStopID);
              return <tr key={stop.OrderStopID}>
                <td>
                  <DropDownButton
                    themeColor={activeStop ? "warning" : deleteStop ? "error" : "primary"}
                    icon={deleteStop ? 'delete' : 'cog'}
                    svgIcon={deleteStop ? trashIcon : gearIcon}
                    disabled={actionMenuItems.length === 0}
                    onItemClick={(e) => {
                      actionMenuItems[e.itemIndex].onClick()
                    }}
                    items={actionMenuItems}
                  />
                </td>
                <td>
                  <Link to={stop.Links.find(x => x.Name === 'Stop').Link}>{stop.EventName}</Link>
                  &nbsp;
                  {getSplitLink(stop.BackwardOrderID, stop.ForwardOrderID, () => setShowOrderSummaryOrderID(details.OrderID))}
                </td>
                <td>
                  <a
                    href="#"
                    className={stop.CanMakeTransitTime ? undefined : "text-danger"}
                    onClick={(e) => {
                      e.preventDefault();
                      toggleTransitTime();
                    }}
                  >
                    {Moment.utc(stop.ScheduledDateTime).tz("America/New_York").format("MM/DD/YYYY HH:mm")} {stop.Verb}
                  </a>
                </td>
                {stop.Links.find(x => x.Name == "Customer") ? <td>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      setShowCustomerID(stop.CustomerID);
                    }}
                  >
                    {stop.LocationName}
                  </a>
                </td> : <td>{stop.LocationName}</td>}
                <td>{stop.AddressLine1}</td>
                <td>{stop.City}, {stop.State} {stop.ZipCode}</td>
                <td>{realFormatter(stop.Distance)}</td>
                <td>{realFormatter(stop.TotalPieces)}</td>
                <td>{realFormatter(stop.TotalWeight)} LBS</td>
                {details.Links.find(x => x.Name === "Trip") ? <td>
                  <Link to={details.Links.find(x => x.Name === 'Trip').Link}>{stop.StatusName}</Link>
                </td> : <td>{stop.StatusName}</td>}
              </tr>
            })}
          </tbody>
        </table>
      </div>
      <ConfirmationDialog />
      {showTripSummary && <TripSummary orderId={parseInt(orderIdParam)} close={toggleTripSummary} />}
      {showMap && <div style={{ width: '100%', height: 500 }}>
        <RouteMap lnglats={details.Stops.map(x => [x.Longitude, x.Latitude])} />
      </div>}
      {showTransitTime && <TransitTime OrderID={orderIdParam} onClose={toggleTransitTime} />}
      {showTurndown && <Turndown
        OrderID={orderIdParam}
        onClose={toggleTurndown}
        save={(reason) => {
          changeStatus(orderStatus, reason);
          toggleTurndown();
        }}
      />}
      {showBooked && <Booked
        BookedByID={details.BookedByID}
        onClose={toggleBooked}
        save={(userId) => {
          changeBookUser(userId);
          toggleBooked();
        }}
      />}
      {showCopy && <Copy
        Loading={loading}
        FirstScheduleDateTime={details.Stops[0].ScheduledDateTime}
        onClose={toggleCopy}
        save={(firstScheduleDateTime, copyCount) => {
          copy(firstScheduleDateTime, copyCount);
        }}
      />}
      {showOrderSummaryOrderID > 0 && <SplitOrderSummary OrderID={showOrderSummaryOrderID} onClose={() => setShowOrderSummaryOrderID(0)} />}
      {showCustomerID > 0 && <CustomerProfile customerId={showCustomerID} isDialog={true} onClose={() => setShowCustomerID(0)} />}
      <OrderContext.Provider value={{ activeStopIds, deleteStopIds, setActiveStopIds, setDeleteStopIds, refresh: refreshOrderDetails, toggleMap }}>
        {props.children}
      </OrderContext.Provider>
    </div>
  );
}

export default OrderDetails;